import {
  AfterViewInit,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import {
  GpxFile,
  GpxWaypoints,
  Track,
  TrackCoordinates,
} from '../../../types/models';
import { INTERACTIVE_WIDGETS } from '../../../constants';
import { NgIf } from '@angular/common';

interface LatLng {
  lat: number;
  lng: number;
}

@Component({
  selector: 'app-interactive-map',
  standalone: true,
  imports: [NgIf],
  templateUrl: './interactive-map.component.html',
  styleUrl: './interactive-map.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class InteractiveMapComponent implements AfterViewInit {
  @Input() gpx: GpxFile | null = null;
  @Input() widget: string | null = null;
  @Input() centerCoordinate: TrackCoordinates | undefined = undefined;
  @Input() raceUnit: string = 'metric';
  @Input() mode?: string = 'immersive';

  icons = {
    start: '',
    finish: '',
    aidStation: '',
    poi: '',
  };

  @ViewChild('mapContainer', { static: false })
  mapContainer!: ElementRef<HTMLDivElement>;
  @ViewChild('map3d') map3dElement!: ElementRef;
  @ViewChild('polyline', { static: false }) polylineElement!: ElementRef;

  map: google.maps.Map | null = null;

  constructor() {
    this.icons.start = '../../../assets/images/start-marker.svg';
    this.icons.finish = '../../../assets/images/finish-icon.svg';
    this.icons.aidStation = '../../../assets/images/water-marker.svg';
    this.icons.poi = '../../../assets/images/point-of-interest.svg';
  }

  async ngAfterViewInit() {
    if (this.mode === 'immersive') {
      this.initializeImmersiveMap();
    }
  }

  async initializeImmersiveMap(): Promise<void> {
    if (!this.gpx) return;

    // @ts-ignore
    const { PinElement } = await google.maps.importLibrary('marker');
    // @ts-ignore
    const { Marker3DElement } = await google.maps.importLibrary('maps3d');

    const map3d = this.map3dElement.nativeElement;
    let centerCoord = this.gpx.tracks[0]?.geometry?.coordinates[0];
    const markers: any[] = [];

    if (this.gpx?.waypoints?.length) {
      const middleWaypoint =
        this.gpx?.waypoints[Math.round((this.gpx?.waypoints.length - 1) / 2)];
      centerCoord = {
        lat: middleWaypoint.geometry?.coordinates[0].lat,
        lon: middleWaypoint.geometry.coordinates[0].lon,
        ele: middleWaypoint.geometry.coordinates[0].ele,
      };

      if (this.centerCoordinate) {
        centerCoord = this.centerCoordinate;
      }

      const startWaypointIndex = this.gpx?.waypoints
        ? this.gpx?.waypoints?.findIndex((i) => i.properties.name === 'Start')
        : -1;

      let startPosition =
        this.gpx?.waypoints[startWaypointIndex]?.geometry.coordinates[0];

      if (startWaypointIndex !== -1 && this.gpx?.waypoints) {
        const coord =
          this.gpx?.waypoints[startWaypointIndex].geometry.coordinates[0];
        // @ts-ignore
        startPosition = new google.maps.LatLng(coord.lat, coord.lon);
      }
      const glyphStartUrl = `${window.location.origin}/assets/images/start-marker.svg`;

      const glyphSvgStartPinElement = new PinElement({
        background: '#4C57BC',
        borderColor: '#4C57BC',
        scale: 1.2,
        glyph: new URL(glyphStartUrl),
      });
      const glyphSvgStartMarker = new Marker3DElement({
        position: startPosition,
      });
      glyphSvgStartMarker.append(glyphSvgStartPinElement);

      const finishWaypointIndex = this.gpx?.waypoints
        ? this.gpx?.waypoints?.findIndex((i) => i.properties.name === 'Finish')
        : -1;

      let finishPosition =
        this.gpx?.waypoints[finishWaypointIndex]?.geometry.coordinates[0];

      if (finishWaypointIndex !== -1 && this.gpx?.waypoints) {
        const coord =
          this.gpx?.waypoints[finishWaypointIndex].geometry.coordinates[0];
        // @ts-ignore
        finishPosition = new google.maps.LatLng(coord.lat, coord.lon);
      }

      const glyphFinishUrl = `${window.location.origin}/assets/images/finish-icon.svg`;

      const glyphSvgFinishPinElement = new PinElement({
        background: '#4C57BC',
        borderColor: '#4C57BC',
        scale: 1.2,
        glyph: new URL(glyphFinishUrl),
      });
      const glyphSvgFinishMarker = new Marker3DElement({
        position: finishPosition,
      });
      glyphSvgFinishMarker.append(glyphSvgFinishPinElement);

      map3d.append(glyphSvgStartMarker);
      map3d.append(glyphSvgFinishMarker);

      for (const waypoint of this.gpx.waypoints) {
        const coord = waypoint.geometry.coordinates[0];

        if (waypoint.properties.type === 'AidStation') {
          const aidMarker = await this.create3DMarker(
            { lat: coord.lat, lng: coord.lon },
            `${window.location.origin}/assets/images/water-marker.svg`,
            '#27A5DC',
          );
          map3d.append(aidMarker);
        } else if (waypoint.properties.type === 'DistanceMarker') {
          const unitCount = Number(waypoint.properties.name.replace(/\D/g, ''));
          const distanceMarker = await this.create3DMarkerWithText(
            { lat: coord.lat, lng: coord.lon },
            unitCount.toString(),
            '#4C57BC',
          );
          map3d.append(distanceMarker);
        }
      }
    }

    map3d.setAttribute('center', `${centerCoord.lat},${centerCoord.lon}`);

    const polyline = this.polylineElement.nativeElement;
    if (polyline) {
      const path = this.getMappedPath(this.gpx.tracks[0]);
      customElements.whenDefined(polyline.localName).then(() => {
        (polyline as any).coordinates = path;
      });
    }

    // Append all markers after the polyline
    markers.forEach((marker) => {
      map3d.append(marker);
    });
  }

  async create3DMarkerWithText(
    position: LatLng,
    text: string,
    backgroundColor: string,
  ) {
    // @ts-ignore
    const { PinElement } = await google.maps.importLibrary('marker');
    // @ts-ignore
    const { Marker3DElement } = await google.maps.importLibrary('maps3d');

    const pinElement = new PinElement({
      glyph: text,
      background: backgroundColor,
      borderColor: 'white',
      scale: 1.2,
      glyphColor: 'white',
    });

    const marker = new Marker3DElement({
      position: new google.maps.LatLng(position.lat, position.lng),
    });

    marker.append(pinElement);

    return marker;
  }

  async create3DMarker(
    position: LatLng,
    glyphUrl: string,
    backgroundColor: string,
  ) {
    // @ts-ignore
    const { PinElement } = await google.maps.importLibrary('marker');
    // @ts-ignore
    const { Marker3DElement } = await google.maps.importLibrary('maps3d');

    const pinElement = new PinElement({
      glyph: new URL(glyphUrl),
      background: backgroundColor,
      borderColor: 'white',
      scale: 1.2,
    });

    const marker = new Marker3DElement({
      position: new google.maps.LatLng(position.lat, position.lng),
    });

    marker.append(pinElement);

    return marker;
  }

  getMappedPath(track: Track | GpxWaypoints) {
    if (track) {
      return track?.geometry?.coordinates.map((i) => ({
        lat: i.lat,
        lng: i.lon,
      }));
    } else {
      return [];
    }
  }
}
