import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HeaderComponent } from '../../../components/header/header.component';
import { UserService } from '../../../services/user/user.service';
import { Router } from '@angular/router';
import { SpinnerServiceService } from '../../../services/spinner-service/spinner-service.service';
import { MatDivider } from '@angular/material/divider';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { MatCard } from '@angular/material/card';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { CustomButtonComponent } from '../../../components/custom-button/custom-button.component';
import { User, UserSettings } from '../../../../types/models';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-user-units',
  standalone: true,
  imports: [
    HeaderComponent,
    MatDivider,
    MatOption,
    MatSelect,
    NgForOf,
    MatCard,
    MatRadioGroup,
    FormsModule,
    MatRadioButton,
    MatIcon,
    NgIf,
    CustomButtonComponent,
    ReactiveFormsModule,
    MatProgressSpinner,
    NgClass,
  ],
  templateUrl: './user-units.component.html',
  styleUrl: './user-units.component.scss',
})
export class UserUnitsComponent implements OnInit {
  @ViewChild('hiddenSubmitButton')
  hiddenSubmitButton!: ElementRef<HTMLButtonElement>;
  user: User | undefined = undefined;
  initialFormValues: any;
  isLoading: boolean = false;

  unitSystems = [
    { value: 'metric', option: 'Metric (km, celsius, kg, cm)' },
    {
      value: 'imperial',
      option: 'Imperial (mile, fahrenheit, pounds, inches)',
    },
  ];

  dateFormats = [
    { value: 'MM/DD/YYYY', option: 'Month / Day / Year' },
    { value: 'DD/MM/YYYY', option: 'Day / Month / Year' },
  ];

  unitForm = new FormGroup({
    unitSystem: new FormControl(),
    dateFormat: new FormControl(),
  });

  constructor(
    private userService: UserService,
    private router: Router,
    public spinnerService: SpinnerServiceService,
  ) {}

  async ngOnInit() {
    this.isLoading = true;
    this.userService.getUser().subscribe((response) => {
      this.user = response;

      this.unitForm.get('unitSystem')?.setValue(response?.settings.units || '');
      this.unitForm
        .get('dateFormat')
        ?.setValue(response?.settings.date_format || '');

      this.initialFormValues = this.unitForm.value;
      this.isLoading = false;
    });
  }

  hasFormChanged(): boolean {
    return (
      JSON.stringify(this.initialFormValues) !==
      JSON.stringify(this.unitForm.value)
    );
  }

  onCustomButtonClick() {
    if (this.unitForm.valid && this.hasFormChanged()) {
      this.hiddenSubmitButton.nativeElement.click();
    }
  }

  onBackClick() {
    this.router.navigate(['/profile']);
  }

  selectUnit(value: string) {
    this.unitForm.get('unitSystem')?.setValue(value);
  }

  selectDate(value: string) {
    this.unitForm.get('dateFormat')?.setValue(value);
  }

  onSubmit() {
    if (this.unitForm.valid) {
      const data: UserSettings = {
        settings: {
          units: this.unitForm.value?.unitSystem ?? this.user?.settings.units,
          date_format:
            this.unitForm.value?.dateFormat ?? this.user?.settings.date_format,
          save_chat_history: this.user?.settings?.save_chat_history || true,
        },
      };

      this.userService.updateUser(data).subscribe(() => {
        this.router.navigate(['/profile']);
      });
    }
  }
}
