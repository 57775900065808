<div>
  <div *ngIf="(races?.length && raceTypes?.length) || demo" class="wrapper" [ngStyle]="{'width': isMobile ? 'auto' : '100%'}">
    <div style="padding-bottom: 8px" [ngStyle]="{'width': isMobile ? '100%' : '600px'}">
      <div style="display: flex; align-items: center; justify-content: right; gap: 8px; margin: 0 16px">
        <div *ngFor="let type of raceTypes">
            <span
              (click)="selectRace(type?.id || '')"
              [ngStyle]="{
                'color': promptColor,
                'fontWeight': type?.id === selectedRace ? '700' : '400',
                'opacity': type?.id === selectedRace ? '1' : '0.6',
              }"
              style="cursor: pointer"
            >
              {{ type?.name }}
            </span>
        </div>
      </div>
    </div>
    <div class="container" [ngStyle]="{'width': isMobile ? '100%' : '600px', 'background': widgetConfiguration?.style?.main_color ? widgetConfiguration?.style?.main_color : 'linear-gradient(to right, #4954B8, #606FF8)'}">
      <div class="search-input-wrapper">
          <span class="search-icon" (click)="sendMessage()">
            <img
              src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9Im1hc2swXzU4M18xOTAzIiBzdHlsZT0ibWFzay10eXBlOmFscGhhIiBtYXNrVW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4PSIwIiB5PSIwIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiPgo8cmVjdCB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9IiNEOUQ5RDkiLz4KPC9tYXNrPgo8ZyBtYXNrPSJ1cmwoI21hc2swXzU4M18xOTAzKSI+CjxwYXRoIGQ9Ik0xOS41NDIzIDIwLjU3N0wxMy4yNjE2IDE0LjI5NkMxMi43NjE2IDE0LjcwODggMTIuMTg2NiAxNS4wMzE5IDExLjUzNjYgMTUuMjY1M0MxMC44ODY2IDE1LjQ5ODYgMTAuMjE0MSAxNS42MTUzIDkuNTE5MyAxNS42MTUzQzcuODEwMTQgMTUuNjE1MyA2LjM2MzY0IDE1LjAyMzUgNS4xNzk4IDEzLjg0QzMuOTk1OTcgMTIuNjU2NSAzLjQwNDA1IDExLjIxMDMgMy40MDQwNSA5LjUwMTUxQzMuNDA0MDUgNy43OTI4NSAzLjk5NTggNi4zNDYxOCA1LjE3OTMgNS4xNjE1MkM2LjM2MjggMy45NzcwMiA3LjgwODk3IDMuMzg0NzcgOS41MTc4IDMuMzg0NzdDMTEuMjI2NSAzLjM4NDc3IDEyLjY3MzEgMy45NzY2OCAxMy44NTc4IDUuMTYwNTFDMTUuMDQyMyA2LjM0NDM1IDE1LjYzNDYgNy43OTA4NSAxNS42MzQ2IDkuNTAwMDFDMTUuNjM0NiAxMC4yMTQyIDE1LjUxNDcgMTAuODk2MyAxNS4yNzUxIDExLjU0NjNDMTUuMDM1MiAxMi4xOTYzIDE0LjcxNTMgMTIuNzYxNiAxNC4zMTUzIDEzLjI0MjNMMjAuNTk2MSAxOS41MjNMMTkuNTQyMyAyMC41NzdaTTkuNTE5MyAxNC4xMTU1QzEwLjgwNzggMTQuMTE1NSAxMS44OTkxIDEzLjY2ODMgMTIuNzkzMyAxMi43NzRDMTMuNjg3NiAxMS44Nzk4IDE0LjEzNDggMTAuNzg4NSAxNC4xMzQ4IDkuNTAwMDFDMTQuMTM0OCA4LjIxMTUxIDEzLjY4NzYgNy4xMjAxOCAxMi43OTMzIDYuMjI2MDFDMTEuODk5MSA1LjMzMTY4IDEwLjgwNzggNC44ODQ1MiA5LjUxOTMgNC44ODQ1MkM4LjIzMDggNC44ODQ1MiA3LjEzOTQ3IDUuMzMxNjggNi4yNDUzIDYuMjI2MDFDNS4zNTA5NyA3LjEyMDE4IDQuOTAzOCA4LjIxMTUxIDQuOTAzOCA5LjUwMDAxQzQuOTAzOCAxMC43ODg1IDUuMzUwOTcgMTEuODc5OCA2LjI0NTMgMTIuNzc0QzcuMTM5NDcgMTMuNjY4MyA4LjIzMDggMTQuMTE1NSA5LjUxOTMgMTQuMTE1NVoiIGZpbGw9IiM2MjcxRkYiLz4KPC9nPgo8L3N2Zz4K"
              alt="Search Icon"
            />
          </span>
        <input
          [(ngModel)]="userMessage"
          placeholder="Ask race event or course questions..."
          class="search-input"
          (keydown.enter)="sendMessage()"
        >
          <span *ngIf="userMessage?.length; else micButton" style="padding: 0; margin: 0; display: flex">
            <span class="divider"></span>
            <button (click)="sendMessage()" class="send-btn">
              <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNSAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9Im1hc2swXzk3Ml82MDUiIHN0eWxlPSJtYXNrLXR5cGU6YWxwaGEiIG1hc2tVbml0cz0idXNlclNwYWNlT25Vc2UiIHg9IjAiIHk9IjAiIHdpZHRoPSIyNSIgaGVpZ2h0PSIyNSI+CjxyZWN0IHg9IjAuMjYyOTM5IiB5PSIwLjM1NTk1NyIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiBmaWxsPSIjRDlEOUQ5Ii8+CjwvbWFzaz4KPGcgbWFzaz0idXJsKCNtYXNrMF85NzJfNjA1KSI+CjxwYXRoIGQ9Ik0xNi44ODk5IDEzLjEwNkg0Ljc2Mjk0VjExLjYwNkgxNi44ODk5TDExLjE5MzcgNS45MDk3MUwxMi4yNjI5IDQuODU1OTZMMTkuNzYyOSAxMi4zNTZMMTIuMjYyOSAxOS44NTZMMTEuMTkzNyAxOC44MDIyTDE2Ljg4OTkgMTMuMTA2WiIgZmlsbD0iIzYyNzFGRiIvPgo8L2c+Cjwvc3ZnPgo=" alt="Arrow forward">
            </button>
          </span>
          <ng-template #micButton>
            <button (click)="openVoiceInput()" class="send-btn">
              <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9Im1hc2swXzkwNV81MTgiIHN0eWxlPSJtYXNrLXR5cGU6YWxwaGEiIG1hc2tVbml0cz0idXNlclNwYWNlT25Vc2UiIHg9IjAiIHk9IjAiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+CjxyZWN0IHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0iI0Q5RDlEOSIvPgo8L21hc2s+CjxnIG1hc2s9InVybCgjbWFzazBfOTA1XzUxOCkiPgo8cGF0aCBkPSJNMTIgMTMuNUMxMS4zMDEzIDEzLjUgMTAuNzEgMTMuMjU4IDEwLjIyNiAxMi43NzRDOS43NDIgMTIuMjkgOS41IDExLjY5ODcgOS41IDExVjVDOS41IDQuMzAxMzMgOS43NDIgMy43MSAxMC4yMjYgMy4yMjZDMTAuNzEgMi43NDIgMTEuMzAxMyAyLjUgMTIgMi41QzEyLjY5ODcgMi41IDEzLjI5IDIuNzQyIDEzLjc3NCAzLjIyNkMxNC4yNTggMy43MSAxNC41IDQuMzAxMzMgMTQuNSA1VjExQzE0LjUgMTEuNjk4NyAxNC4yNTggMTIuMjkgMTMuNzc0IDEyLjc3NEMxMy4yOSAxMy4yNTggMTIuNjk4NyAxMy41IDEyIDEzLjVaTTExLjI1IDIwLjc1VjE3LjQ1MzhDOS42IDE3LjI2NTMgOC4yMjkxNyAxNi41NTc2IDcuMTM3NSAxNS4zMzA3QzYuMDQ1ODMgMTQuMTAzNyA1LjUgMTIuNjYwMiA1LjUgMTFIN0M3IDEyLjM4MzMgNy40ODc1IDEzLjU2MjUgOC40NjI1IDE0LjUzNzVDOS40Mzc1IDE1LjUxMjUgMTAuNjE2NyAxNiAxMiAxNkMxMy4zODMzIDE2IDE0LjU2MjUgMTUuNTEyNSAxNS41Mzc1IDE0LjUzNzVDMTYuNTEyNSAxMy41NjI1IDE3IDEyLjM4MzMgMTcgMTFIMTguNUMxOC41IDEyLjY2MDIgMTcuOTU0MiAxNC4xMDM3IDE2Ljg2MjUgMTUuMzMwN0MxNS43NzA4IDE2LjU1NzYgMTQuNCAxNy4yNjUzIDEyLjc1IDE3LjQ1MzhWMjAuNzVIMTEuMjVaIiBmaWxsPSIjN0U3RTdFIi8+CjwvZz4KPC9zdmc+Cg==" alt="Mic">
            </button>
          </ng-template>
          <p class="error" *ngIf="error">{{error}}</p>
      </div>
      <app-prompts-list
        [disabled]="false"
        [prompts]="prompts"
        (handlePromptClick)="selectDefaultPrompt($event)"
        [isMobile]="isMobile"
        [mainColor]="widgetConfiguration?.style?.main_color || '#6271FF'"
      ></app-prompts-list>
    </div>
  </div>
<!--  <ng-container *ngIf="showLoginIframe">-->
<!--    <app-auth-drawer [isOpen]="isDrawerOpen" [keycloakLoginUrl]="keycloakLoginUrl" (handleChangeDrawerState)="handleChangeDrawerState($event)"></app-auth-drawer>-->
<!--  </ng-container>-->
  <ng-container *ngIf="isAIDrawerOpen">
    <app-ai-chat-wrapper
      [isOpen]="isAIDrawerOpen"
      (handleChangeDrawerState)="handleChangeDrawerState($event)"
      [prompt]="selectedPrompt"
      [raceId]="selectedRace"
      [races]="races"
      [raceTypes]="raceTypes"
      [isVoiceMode]="isVoiceMode"
      [mainColor]="widgetConfiguration?.style?.main_color || '#6271FF'"
    >
    </app-ai-chat-wrapper>
<!--    <iframe-->
<!--      id="login-iframe"-->
<!--      src="https://ai-dev.neurun.com/ai-chat"-->
<!--      class="login-iframe"-->
<!--    ></iframe>-->
<!--    <app-ai-chat-->
<!--      [isOpen]="isAIDrawerOpen"-->
<!--      [prompt]="selectedPrompt"-->
<!--      [elevations_data]="elevationsData"-->
<!--      [units]="userUnitSystem"-->
<!--      (handleChangeDrawerState)="handleChangeDrawerState($event)"-->
<!--      [token]="token"-->
<!--    ></app-ai-chat>-->
  </ng-container>
</div>
