import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GoogleMap, MapCircle, MapMarker } from '@angular/google-maps';
import { NgForOf, NgIf } from '@angular/common';

interface LatLng {
  lat: number;
  lng: number;
}

@Component({
  selector: 'app-map-with-radius',
  standalone: true,
  imports: [GoogleMap, MapCircle, MapMarker, NgIf, NgForOf],
  templateUrl: './map-with-radius.component.html',
  styleUrls: ['./map-with-radius.component.scss'],
})
export class MapWithRadiusComponent implements OnInit {
  @Input() startPoint: LatLng | null = null;
  @Input() userUnit: string = '';
  @Input() mainColor?: string = '';
  @Output() handleSelectRadius = new EventEmitter<{
    radius: number;
    center: LatLng;
  }>();
  minRadius: number = 1;
  maxRadius: number = 2;
  radiusOptions: number[] = [];
  selectedRadius: number = 0;
  circleRadius: number = 2;
  circleCenter: google.maps.LatLngLiteral | null = null;
  zoom = 14;

  mapOptions: google.maps.MapOptions = {
    streetViewControl: false,
    zoomControl: true,
    mapTypeControl: false,
    fullscreenControl: false,
    clickableIcons: false,
  };

  markerOptions: google.maps.MarkerOptions = {
    icon: {
      path: google.maps.SymbolPath.CIRCLE,
      scale: 10,
      fillColor: '#007AFF',
      fillOpacity: 1,
      strokeColor: '#ffffff',
      strokeWeight: 3,
    },
  };

  circleOptions: google.maps.CircleOptions = {
    strokeColor: '#007AFF',
    strokeOpacity: 0.8,
    strokeWeight: 3,
    fillColor: '#007AFF',
    fillOpacity: 0.35,
    radius: this.circleRadius,
    center: undefined, // Ensure it starts as undefined
  };

  ngOnInit() {
    this.radiusOptions = this.userUnit === 'imperial' ? [0.5, 1.5] : [1, 2];
    this.selectedRadius = this.radiusOptions[0];

    const root = document.documentElement;
    root.style.setProperty('--main-color', this.mainColor || '#6271FF');

    if (this.startPoint) {
      this.setCircleAtStartPoint();
    }

    if (this.userUnit === 'imperial') {
      this.minRadius = 0.5;
      this.maxRadius = 1.5;
      this.radiusOptions = [0.5, 1.5];
      this.selectedRadius = 0.5;
      this.zoom = 13;
      this.handleSelectRadius.emit({
        radius: this.selectedRadius * 1609.34,
        center: this.startPoint!,
      });
    }
  }

  onMapClick(event: google.maps.MapMouseEvent) {
    if (event.latLng) {
      // Always update the startPoint when the user clicks on the map
      this.startPoint = { lat: event.latLng.lat(), lng: event.latLng.lng() };
      this.circleCenter = {
        lat: this.startPoint.lat,
        lng: this.startPoint.lng,
      };
      this.setCircleAtStartPoint();
    }
  }

  setCircleAtStartPoint() {
    this.circleCenter = {
      lat: this.startPoint!.lat,
      lng: this.startPoint!.lng,
    };
    this.circleRadius =
      this.userUnit === 'imperial'
        ? this.selectedRadius * 1609.34
        : this.selectedRadius * 1000;

    this.circleOptions = {
      ...this.circleOptions,
      center: this.circleCenter, // Ensure the center is set
      radius: this.circleRadius, // Ensure the radius is updated
    };

    this.markerOptions = {
      ...this.markerOptions,
      position: this.circleCenter, // Update the marker position
    };

    // Emit the new radius and center point
    this.handleSelectRadius.emit({
      radius: this.circleRadius,
      center: this.startPoint!,
    });
  }

  incrementRadius() {
    const currentIndex = this.radiusOptions.indexOf(this.selectedRadius);
    if (currentIndex < this.radiusOptions.length - 1) {
      const radius = this.radiusOptions[currentIndex + 1];
      this.selectedRadius = radius;
      this.updateCircleRadius();
      this.zoom -= 1;
    }
  }

  decrementRadius() {
    const currentIndex = this.radiusOptions.indexOf(this.selectedRadius);
    if (currentIndex > 0) {
      const radius = this.radiusOptions[currentIndex - 1];
      this.selectedRadius = radius;
      this.updateCircleRadius();
      this.zoom += 1;
    }
  }

  updateCircleRadius() {
    const radiusInKm = this.selectedRadius;
    if (this.userUnit === 'imperial') {
      this.circleRadius = radiusInKm * 1609.34;
    } else {
      this.circleRadius = radiusInKm * 1000;
    }

    this.circleOptions = {
      ...this.circleOptions,
      radius: this.circleRadius,
    };

    this.handleSelectRadius.emit({
      radius: radiusInKm * 1000,
      center: this.startPoint!,
    });
  }
}
