import { APP_INITIALIZER, ApplicationConfig, Provider } from '@angular/core';
import { provideRouter } from '@angular/router';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { keycloakOptions } from '../keycloak.config';
import { KeycloakBearerInterceptor, KeycloakService } from 'keycloak-angular';
import { AuthInterceptor } from './interceptors/http-interceptor/auth.interceptor';
import { provideOAuthClient } from 'angular-oauth2-oidc';

function initializeKeycloak(keycloak: KeycloakService) {
  return () => keycloak.init(keycloakOptions);
}

const KeycloakBearerInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: KeycloakBearerInterceptor,
  multi: true,
};

const KeycloakInitializerProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initializeKeycloak,
  multi: true,
  deps: [KeycloakService],
};

const SpinnerInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true,
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptorsFromDi()),
    provideEnvironmentNgxMask(),
    KeycloakBearerInterceptorProvider,
    KeycloakInitializerProvider,
    SpinnerInterceptorProvider,
    KeycloakService,
    provideOAuthClient({
      resourceServer: {
        sendAccessToken: true,
      },
    }),
  ],
};
