import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AsyncPipe,
  NgClass,
  NgForOf,
  NgIf,
  NgOptimizedImage,
} from '@angular/common';
import { CustomButtonComponent } from '../../../components/custom-button/custom-button.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from '../../../components/header/header.component';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { UserService } from '../../../services/user/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerServiceService } from '../../../services/spinner-service/spinner-service.service';
import { MatCard } from '@angular/material/card';
import { MatDivider } from '@angular/material/divider';
import { OidcServiceService } from '../../../services/oidcService/oidc-service.service';
import { AthleteData } from '../../../../types/models';
import { DialogComponent } from '../../../components/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';

interface ConnectedAccounts {
  logo: string;
  name: string;
  status: string;
  connected: boolean;
  providerName: string;
}

@Component({
  selector: 'app-user-accounts',
  standalone: true,
  imports: [
    AsyncPipe,
    CustomButtonComponent,
    FormsModule,
    HeaderComponent,
    MatFormField,
    MatInput,
    NgIf,
    ReactiveFormsModule,
    NgOptimizedImage,
    MatCard,
    NgForOf,
    NgClass,
    MatDivider,
  ],
  templateUrl: './user-accounts.component.html',
  styleUrl: './user-accounts.component.scss',
})
export class UserAccountsComponent implements OnInit, OnDestroy {
  private runkeeperToken: string = '';
  private stravaToken: string = '';
  private hasRunkeeperTokenProcessed = false;
  private hasStravaTokenProcessed = false;
  private currentRunkeeperAccount: ConnectedAccounts | null = null;
  private currentStravaAccount: ConnectedAccounts | null = null;
  private stravaRefreshToken: string = '';
  integrations: ConnectedAccounts[] = [
    // { logo: 'assets/images/asics-logo.svg', name: 'ASICS Runkeeper', status: 'Connected', connected: false },
    // { logo: 'assets/images/apple-logo.svg', name: 'Apple Health', status: 'Not Connected', connected: false },
    // { logo: 'assets/images/garmin-logo.svg', name: 'Garmin', status: 'Not Connected', connected: false },
    // { logo: 'assets/images/fitbit-logo.svg', name: 'Fitbit', status: 'Not Connected', connected: false },
    // { logo: 'assets/images/strava-logo.svg', name: 'Strava', status: 'Not Connected', connected: false },
    // { logo: 'assets/images/samsung-health-logo.svg', name: 'Samsung Health', status: 'Not Connected', connected: false }
  ];

  constructor(
    private userService: UserService,
    private router: Router,
    public spinnerService: SpinnerServiceService,
    private readonly oidcService: OidcServiceService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    const runkeeperData = localStorage.getItem('runkeeper_token') || '';
    const stravaData = localStorage.getItem('strava_token') || '';
    let isRunkeeperAccountConnected = false;
    let isStravaAccountConnected = false;
    if (runkeeperData) {
      const parsedData = JSON.parse(runkeeperData);
      this.runkeeperToken = parsedData.token.replace('Bearer ', '');
      isRunkeeperAccountConnected = true;
    }
    if (stravaData) {
      const parsedData = JSON.parse(stravaData);
      this.stravaToken = parsedData.token.replace('Bearer ', '');
      this.stravaRefreshToken = parsedData?.refresh_token;
      isStravaAccountConnected = true;
    }
    this.integrations.push({
      logo: 'assets/images/asics-logo.svg',
      name: 'ASICS Runkeeper',
      status: isRunkeeperAccountConnected ? 'Connected' : 'Not Connected',
      providerName: 'runkeeper',
      connected: isRunkeeperAccountConnected,
    });
    this.integrations.push({
      logo: 'assets/images/strava-logo.svg',
      name: 'Strava',
      status: isStravaAccountConnected ? 'Connected' : 'Not Connected',
      providerName: 'strava',
      connected: isStravaAccountConnected,
    });

    window.addEventListener('storage', (e) => this.onRunkeeperToken(e));
  }

  onRunkeeperToken(event: StorageEvent) {
    if (!this.router.url.includes('user-connected-accounts')) return;
    if (event.storageArea !== localStorage) return;
    if (event.key === 'runkeeper_token' && event.newValue) {
      if (this.hasRunkeeperTokenProcessed) return;
      const runkeeperData = JSON.parse(event.newValue);
      if (runkeeperData.token) {
        this.runkeeperToken = runkeeperData.token.replace('Bearer ', '');
        if (this.currentRunkeeperAccount) {
          this.changeStatus(this.currentRunkeeperAccount);
        }
        this.hasRunkeeperTokenProcessed = true;

        const profile$ = this.userService.getRunkeeperProfileData(
          this.runkeeperToken,
        );
        const weight$ = this.userService.getRunkeeperUserWeightData(
          this.runkeeperToken,
        );

        forkJoin({ profile: profile$, weight: weight$ }).subscribe(
          ({ profile, weight }) => {
            const data = {
              sex: profile?.data.gender,
              weight: weight.data.items?.length
                ? weight.data.items[0]?.weight
                : 0,
            };
            this.openDialog(data);
          },
          (error) => {
            console.error('Error fetching Runkeeper data:', error);
          },
        );
      }
      window.removeEventListener('storage', this.onRunkeeperToken);
    }
    if (event.key === 'strava_token' && event.newValue) {
      if (this.hasStravaTokenProcessed) return;
      const athleteData = localStorage.getItem('athlete_data');
      if (athleteData) {
        const parsedData = JSON.parse(athleteData);
        this.openDialog(parsedData);
      }
      const stravaData = JSON.parse(event.newValue);
      if (stravaData.token) {
        this.stravaToken = stravaData.token.replace('Bearer ', '');
        this.stravaRefreshToken = stravaData.refresh_token;
        if (this.currentStravaAccount) {
          this.changeStatus(this.currentStravaAccount);
        }
        this.hasStravaTokenProcessed = true;
      }
      window.removeEventListener('storage', this.onRunkeeperToken);
    }
  }

  openDialog(data: AthleteData) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '300px',
      data: {
        headerTitle: 'Sync Fitness Data?',
        contentText: `Your fitness data will be synchronized with Neurun.`,
        submitBtnText: 'Submit',
        onSubmit: () => {
          this.updateUserFitnessInfo(data);
        },
        onCancel: () => {},
        showCheckbox: false,
      },
    });
  }

  updateUserFitnessInfo(data: AthleteData) {
    let gender = null;

    if (data?.sex === 'M') {
      gender = 'male';
    } else if (data?.sex === 'W') {
      gender = 'female';
    }
    this.userService
      .updateUser({
        fitness_information: { weight: Math.round(data?.weight) },
        gender: gender || '',
      })
      .subscribe(() => {});
  }

  ngOnDestroy() {
    window.removeEventListener('storage', this.onRunkeeperToken);
  }

  changeStatus(integration: ConnectedAccounts) {
    integration.connected = !integration.connected;
    integration.status = integration.connected ? 'Connected' : 'Not Connected';
  }

  toggleConnection(integration: ConnectedAccounts) {
    if (integration.providerName === 'runkeeper') {
      if (this.runkeeperToken) {
        this.oidcService
          .logout(this.runkeeperToken, 'runkeeper')
          .subscribe(() => {
            localStorage.removeItem('runkeeper_token');
            this.changeStatus(integration);
            this.currentRunkeeperAccount = integration;
            this.runkeeperToken = '';
          });
      } else {
        this.oidcService.openAuthWindow('runkeeper');
        this.currentRunkeeperAccount = integration;
      }
    }

    if (integration.providerName === 'strava') {
      if (this.stravaToken) {
        // @ts-ignore
        this.oidcService.logoutStrava(this.stravaRefreshToken).subscribe(() => {
          localStorage.removeItem('strava_token');
          this.changeStatus(integration);
          this.currentStravaAccount = integration;
          this.stravaToken = '';
        });
      } else {
        this.oidcService.openAuthWindow('strava');
        this.currentStravaAccount = integration;
      }
    }
  }

  onBackClick() {
    this.router.navigate(['/profile']);
  }
}
