import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgIf } from '@angular/common';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-auth-drawer',
  standalone: true,
  imports: [NgIf],
  templateUrl: './auth-drawer.component.html',
  styleUrl: './auth-drawer.component.scss',
})
export class AuthDrawerComponent {
  @Input() isOpen: boolean = false;
  @Input() keycloakLoginUrl: SafeResourceUrl | string = '';
  @Output() handleChangeDrawerState = new EventEmitter<boolean>();

  closeDrawer() {
    this.isOpen = false;
    this.handleChangeDrawerState.emit(false);
  }
}
