import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';

interface LatLng {
  lat: number;
  lng: number;
}

@Component({
  selector: 'app-routes-map',
  standalone: true,
  imports: [],
  templateUrl: './routes-map.component.html',
  styleUrl: './routes-map.component.scss',
})
export class RoutesMapComponent implements OnChanges {
  @Input() paths: LatLng[][] = [];
  @Input() raceUnit: string = 'metric';

  @ViewChild('mapContainer', { static: false })
  mapContainer!: ElementRef<HTMLDivElement>;

  map: google.maps.Map | null = null;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['paths'] && this.paths.length > 0) {
      this.initializeMap();
    }
  }

  initializeMap() {
    const loader = new Loader({
      apiKey: 'YOUR_API_KEY',
      libraries: ['geometry'],
    });

    loader.load().then(() => {
      const mapOptions: google.maps.MapOptions = {
        center: new google.maps.LatLng(
          this.paths[0][0].lat,
          this.paths[0][0].lng,
        ),
        zoom: 14,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        streetViewControl: true,
        zoomControl: true,
        disableDefaultUI: true,
      };

      this.map = new google.maps.Map(
        this.mapContainer.nativeElement,
        mapOptions,
      );

      this.paths.forEach((path, index) => {
        const lineCoordinates = path.map(
          (point) => new google.maps.LatLng(point.lat, point.lng),
        );

        new google.maps.Polyline({
          path: lineCoordinates,
          strokeColor: '#6271FF',
          strokeOpacity: 2.0,
          strokeWeight: 5,
          map: this.map,
        });

        this.addTeardropNumberedMarker(path[0], index + 1);
      });
    });
  }

  addTeardropNumberedMarker(position: LatLng, number: number) {
    const icon = this.createTeardropNumberedIcon(number);

    new google.maps.Marker({
      position: new google.maps.LatLng(position.lat, position.lng),
      map: this.map,
      icon: icon,
    });
  }

  createTeardropNumberedIcon(number: number): google.maps.Icon {
    const canvas = document.createElement('canvas');
    const size = 35;
    const devicePixelRatio = window.devicePixelRatio || 1;
    const canvasSize = size * devicePixelRatio;

    canvas.width = canvasSize;
    canvas.height = canvasSize * 1.5;
    const context = canvas.getContext('2d');

    if (context) {
      context.scale(devicePixelRatio, devicePixelRatio);

      context.beginPath();
      context.arc(size / 2, size / 2, size / 2, 0, Math.PI, true);
      context.lineTo(size / 2, size * 1.3);
      context.closePath();
      context.fillStyle = '#FFFFFF';
      context.fill();

      context.beginPath();
      context.arc(size / 2, size / 2, size / 2 - 3, 0, Math.PI, true);
      context.lineTo(size / 2, size * 1.25);
      context.closePath();
      context.fillStyle = '#6271FF';
      context.fill();

      context.fillStyle = '#FFFFFF';
      context.font = `${10 * (devicePixelRatio / 1)}px Arial`;
      context.textAlign = 'center';
      context.textBaseline = 'middle';
      context.fillText(number.toString(), size / 2, size / 1.75);
    }

    return {
      url: canvas.toDataURL(),
      scaledSize: new google.maps.Size(size, size * 1.5),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(size / 2, size * 1.25),
    };
  }
}
