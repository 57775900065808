import { Component, OnInit } from '@angular/core';
import { CustomButtonComponent } from '../custom-button/custom-button.component';
import { RACE_ID } from '../../../constants';
import { Location, NgIf } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-fallback-page',
  standalone: true,
  imports: [CustomButtonComponent, NgIf],
  templateUrl: './fallback-page.component.html',
  styleUrl: './fallback-page.component.scss',
})
export class FallbackPageComponent implements OnInit {
  public race_id: string = '';

  constructor(
    private router: Router,
    private _location: Location,
  ) {}

  ngOnInit() {
    const raceId = sessionStorage.getItem(RACE_ID);

    if (raceId) {
      this.race_id = raceId;
    }
  }

  onPageReload() {
    this._location.back();
  }

  onRaceNavigate() {
    this.router.navigate(['my-races/race', this.race_id]);
  }
}
