import { Component, OnInit } from '@angular/core';
import { SearchInputComponent } from '../../components/search-input/search-input.component';
import { MatIcon } from '@angular/material/icon';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-find-a-race',
  standalone: true,
  imports: [SearchInputComponent, MatIcon],
  templateUrl: './find-a-race.component.html',
  styleUrl: './find-a-race.component.scss',
})
export class FindARaceComponent implements OnInit {
  searchText: string = '';

  constructor(
    private router: Router,
    private readonly keycloakService: KeycloakService,
  ) {}

  async ngOnInit() {
    const isTokenExpired = this.keycloakService.isTokenExpired();

    if (isTokenExpired) {
      await this.keycloakService.login();
    }
  }

  onSearchChange(searchText: string) {
    this.searchText = searchText;
  }

  onManualAdd() {
    this.router.navigate(['/my-races/add-race']);
  }
}
