import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsServiceService {
  private apiKey = environment.googleMapsAPIKey;
  private placesApiUrl = 'https://maps.googleapis.com/maps/api/place/photo';
  private placePhotoUrl = 'https://maps.googleapis.com/maps/api/place/photo';

  constructor(private http: HttpClient) {}

  // Get nearby places by coordinates (to retrieve place_id)
  getNearbyPlaces(lat: number, lng: number): Observable<any> {
    const location = `${lat},${lng}`;
    const radius = 500; // Search within a 500 meter radius
    const type = 'tourist_attraction'; // You can specify the type of places you're looking for
    const url = `${this.placesApiUrl}?location=${location}&radius=${radius}&type=${type}&key=${this.apiKey}`;
    return this.http.get(url);
  }

  // Get place photo by reference (place_id)
  // getPlacePhoto(photoReference: string): string {
  //   const maxWidth = 400; // Width of the photo
  //   return `${this.placePhotoUrl}?maxwidth=${maxWidth}&photoreference=${photoReference}&key=${this.apiKey}`;
  // }

  getStreetViewPhotoUrl(lat: number, lng: number): string {
    const apiKey = 'AIzaSyDsoZfoY75xr-6bUPgVNrsgFsJ59u0Yohw'; // Replace with your actual API key
    const streetViewUrl = `https://maps.googleapis.com/maps/api/streetview?size=400x400&location=${lat},${lng}&key=${apiKey}`;

    return streetViewUrl; // Return the Street View photo URL
  }
}
