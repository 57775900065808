import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { catchError, from, Observable, throwError } from 'rxjs';
import {
  CodeCheckResponse,
  User,
  UserFitnessInformation,
  UserNameInfo,
  UserSettings,
} from '../../../types/models';
import axios from 'axios';
import { CacheService } from '../cache-service/cache.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private apiUrl: string = environment.baseUrl;
  private wsUrl: string = environment.wsUrl;
  private getUserUrl: string = `${this.apiUrl}/user/myself`;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    private http: HttpClient,
    private cacheService: CacheService,
  ) {}

  getUser(): Observable<User> {
    if (this.cacheService.has(this.getUserUrl)) {
      return this.cacheService.get(this.getUserUrl) as Observable<User>;
    } else {
      return this.http.get<User>(this.getUserUrl).pipe(
        tap((response) => this.cacheService.set(this.getUserUrl, response)),
        catchError(this.handleError),
      );
    }
  }

  updateUser(
    data:
      | UserNameInfo
      | {
          fitness_information?: UserFitnessInformation;
          gender?: string;
          about_me?: string;
        }
      | UserSettings,
  ): Observable<UserNameInfo> {
    const url = `${this.apiUrl}/user/myself`;
    return this.http.put<UserNameInfo>(url, data, this.httpOptions).pipe(
      tap(() => this.cacheService.delete(this.getUserUrl)),
      catchError(this.handleError),
    );
  }

  uploadUserAvatar(file: FormData): Observable<{ url: string }> {
    const url = `${this.apiUrl}/user/avatar`;
    return this.http.post<{ url: string }>(url, file).pipe(
      tap(() => this.cacheService.delete(this.getUserUrl)),
      catchError(this.handleError),
    );
  }

  deleteUser() {
    const url = `${this.apiUrl}/user/myself`;
    return this.http.delete<{ url: string }>(url).pipe(
      tap(() => this.cacheService.delete(this.getUserUrl)),
      catchError(this.handleError),
    );
  }

  getRunkeeperProfileData(token: string) {
    return from(
      axios.get('https://ai-dev.neurun.com/api-rk/profile', {
        headers: {
          'Content-Type': 'application/vnd.com.runkeeper.Profile+json',
          Authorization: `Bearer ${token}`,
          accept: '*/*',
        },
      }),
    );
  }

  getRunkeeperUserWeightData(token: string) {
    return from(
      axios.get('https://ai-dev.neurun.com/api-rk/weight', {
        headers: {
          'Content-Type': 'application/vnd.com.runkeeper.Weight+json',
          Authorization: `Bearer ${token}`,
          accept: '*/*',
        },
      }),
    );
  }

  checkAccessCode(code: string): Observable<CodeCheckResponse> {
    const url = `${this.apiUrl}/user/invitation/${code}`;
    return this.http
      .get<CodeCheckResponse>(url)
      .pipe(catchError(this.handleError));
  }

  sendAccessCode(code: string): Observable<CodeCheckResponse> {
    const url = `${this.apiUrl}/user/invitation/${code}`;
    return this.http.post<CodeCheckResponse>(url, null);
  }

  sendActivityToken(data: {
    token_secret?: string;
    token: string;
    account_type: string;
  }): Observable<CodeCheckResponse> {
    const url = `${this.wsUrl}/api/ws/user-activity/token`;
    return this.http.post<CodeCheckResponse>(url, data);
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = 'An unknown error occurred!';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(() => errorMessage);
  }
}
