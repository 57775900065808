export const weightConverter = (
  value: number = 0,
  unit: string = 'metric',
): number => {
  if (unit === 'imperial') {
    const pounds = value * 2.20462;
    return Number(pounds.toFixed(0));
  } else if (unit === 'metric') {
    const kilograms = value / 2.20462;
    return Number(kilograms.toFixed(0));
  } else {
    return value;
  }
};
