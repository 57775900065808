<div class="drawer-overlay" *ngIf="isOpen" (click)="closeDrawer()"></div>
<div class="drawer" [class.open]="isOpen">
  <div style="height: 100%">
    <iframe
      id="login-iframe"
      [src]="keycloakLoginUrl"
      class="login-iframe"
    ></iframe>
  </div>
</div>
