export const heightConverter = (
  value: number = 0,
  unit: string = 'metric',
): number => {
  if (unit === 'imperial') {
    const inchesTotal = value / 2.54;
    const feet = Math.floor(inchesTotal / 12);
    const inches = (inchesTotal % 12).toFixed(0);
    return Number(
      `${feet}${String(inches)?.length >= 2 ? String(inches)?.slice(0, -1) : inches}`,
    );
  } else if (unit === 'metric') {
    const feet = Math.floor(value);
    const inches = (value - feet) * 12;
    const totalInches = Number((feet * 12 + inches).toFixed(0));
    const centimeters = Number((totalInches * 2.54).toFixed(0));
    return Number(centimeters.toFixed(0).slice(0, -1));
  } else {
    return value;
  }
};
