<button
  class="btn"
  type="{{type}}}"
  [disabled]="disabled"
  (click)="onCLick()"
  [ngClass]="{ 'primary-btn': variant === 'primary', 'secondary-btn': variant === 'secondary', 'outlined-btn': variant === 'outlined' }"
  [ngStyle]="{ 'background': customBackground || '' }"
>
  {{ title }}
</button>
