import {
  AfterViewInit,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { GpxWaypoints } from '../../../types/models';

interface LatLng {
  lat: number;
  lng: number;
}

@Component({
  selector: 'app-map-immersive-view',
  standalone: true,
  imports: [],
  templateUrl: './map-immersive-view.component.html',
  styleUrl: './map-immersive-view.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MapImmersiveViewComponent implements AfterViewInit {
  @Input() path: LatLng[] = [];
  @Input() startPoint: LatLng | null = null;
  @Input() endPoint: LatLng | null = null;
  @Input() raceUnit: string = 'metric';
  @Input() waypoints: GpxWaypoints[] | null = [];
  @ViewChild('map3d') map3dElement!: ElementRef;

  constructor() {}

  async ngAfterViewInit(): Promise<void> {
    const map3d = this.map3dElement.nativeElement;
    map3d.setAttribute(
      'center',
      `${this.startPoint?.lat},${this.startPoint?.lng}`,
    );
    // @ts-ignore
    const { Marker3DElement } = await google.maps.importLibrary('maps3d');
    // @ts-ignore
    const { PinElement } = await google.maps.importLibrary('marker');

    const startWaypointIndex = this.waypoints
      ? this.waypoints?.findIndex((i) => i.properties.name === 'Start')
      : -1;

    let startPosition = new google.maps.LatLng(
      this.startPoint!.lat,
      this.startPoint!.lng,
    );

    if (startWaypointIndex !== -1 && this.waypoints) {
      const coord = this.waypoints[startWaypointIndex].geometry.coordinates[0];
      startPosition = new google.maps.LatLng(coord.lat, coord.lon);
    }
    const glyphStartUrl = `${window.location.origin}/assets/images/start-marker.svg`;

    const glyphSvgStartPinElement = new PinElement({
      background: '#4C57BC',
      borderColor: '#4C57BC',
      scale: 1.2,
      glyph: new URL(glyphStartUrl),
    });
    const glyphSvgStartMarker = new Marker3DElement({
      position: startPosition,
    });
    glyphSvgStartMarker.append(glyphSvgStartPinElement);

    const finishWaypointIndex = this.waypoints
      ? this.waypoints?.findIndex((i) => i.properties.name === 'Finish')
      : -1;

    let finishPosition = new google.maps.LatLng(
      this.endPoint!.lat,
      this.endPoint!.lng,
    );

    if (finishWaypointIndex !== -1 && this.waypoints) {
      const coord = this.waypoints[finishWaypointIndex].geometry.coordinates[0];
      finishPosition = new google.maps.LatLng(coord.lat, coord.lon);
    }

    const glyphFinishUrl = `${window.location.origin}/assets/images/finish-icon.svg`;

    const glyphSvgFinishPinElement = new PinElement({
      background: '#4C57BC',
      borderColor: '#4C57BC',
      scale: 1.2,
      glyph: new URL(glyphFinishUrl),
    });
    const glyphSvgFinishMarker = new Marker3DElement({
      position: finishPosition,
    });
    glyphSvgFinishMarker.append(glyphSvgFinishPinElement);

    map3d.append(glyphSvgStartMarker);
    map3d.append(glyphSvgFinishMarker);

    if (this.waypoints) {
      for (const waypoint of this.waypoints) {
        if (
          waypoint.properties.type !== 'DistanceMarker' &&
          waypoint.properties.type !== 'Start' &&
          waypoint.properties.type !== 'Finish' &&
          waypoint?.properties?.type === 'AidStation'
        ) {
          const coordinates = waypoint.geometry.coordinates[0];
          const position = new google.maps.LatLng(
            coordinates.lat,
            coordinates.lon,
          );
          const glyphAidStationUrl = `${window.location.origin}/assets/images/water-marker.svg`;

          const glyphSvgAidStationPinElement = new PinElement({
            background: '#27A5DC',
            borderColor: 'white',
            scale: 1.2,
            glyph: new URL(glyphAidStationUrl),
          });
          const glyphSvgAidStationMarker = new Marker3DElement({
            position: position,
          });
          glyphSvgAidStationMarker.append(glyphSvgAidStationPinElement);
          map3d.append(glyphSvgAidStationMarker);
        }
        if (waypoint.properties.type === 'DistanceMarker') {
          const coordinates = waypoint.geometry.coordinates[0];

          const unitCount = Number(waypoint.properties.name.replace(/\D/g, ''));

          const pinTextGlyph = new PinElement({
            glyph: unitCount.toString(),
            scale: 1.2,
            glyphColor: 'white',
            background: '#4C57BC',
            borderColor: 'white',
          });

          const markerWithGlyphText = new Marker3DElement({
            position: new google.maps.LatLng(coordinates.lat, coordinates.lon),
          });
          markerWithGlyphText.append(pinTextGlyph);
          map3d.append(markerWithGlyphText);
        }
      }
    }

    const polyline = document.querySelector('gmp-polyline-3d');
    if (polyline) {
      customElements.whenDefined(polyline.localName).then(() => {
        (polyline as any).coordinates = this.path;
      });
    }
  }
}
