import { Component, OnInit } from '@angular/core';
import { HeaderComponent } from '../../components/header/header.component';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { TextFieldComponent } from '../../components/text-field/text-field.component';
import { DateFieldComponent } from '../../components/date-field/date-field.component';
import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { MatInput, MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatOption } from '@angular/material/core';
import {
  MatButtonToggle,
  MatButtonToggleGroup,
} from '@angular/material/button-toggle';
import { NgIf, NgOptimizedImage } from '@angular/common';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import {
  AutocompleteAddress,
  Coordinates,
  RacePayload,
  SelectorOptions,
} from '../../../types/models';
import { Router } from '@angular/router';
import { CustomButtonComponent } from '../../components/custom-button/custom-button.component';
import { RaceService } from '../../services/race/race.service';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { MapAutocompleteComponent } from '../../components/map-autocomplete/map-autocomplete.component';
import { GeocodingService } from '../../services/geocoding/geocoding.service';
import moment from 'moment-timezone';
import { distanceConverter } from '../../helpers/distanceConverter';
import { USER_DATE_FORMAT, USER_UNIT_SYSTEM } from '../../../constants';

@Component({
  selector: 'app-add-new-race',
  standalone: true,
  imports: [
    HeaderComponent,
    ReactiveFormsModule,
    TextFieldComponent,
    DateFieldComponent,
    MatFormField,
    MatInput,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatButtonToggleGroup,
    MatButtonToggle,
    NgIf,
    NgOptimizedImage,
    MatSelect,
    MatOption,
    MatSelectModule,
    CustomButtonComponent,
    MatNativeDateModule,
    MatIcon,
    MatIconButton,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxMaskDirective,
    MapAutocompleteComponent,
  ],
  templateUrl: './add-new-race.component.html',
  providers: [provideNgxMask()],
  styleUrl: './add-new-race.component.scss',
})
export class AddNewRaceComponent implements OnInit {
  surfaceTypeOptions: SelectorOptions[] = [
    { value: 'road', option: 'Road' },
    { value: 'trail', option: 'Trail' },
    { value: 'mixed', option: 'Mixed' },
  ];
  unitOptions: SelectorOptions[] = [
    { value: 'metric', option: 'Kilometers' },
    { value: 'imperial', option: 'Miles' },
  ];
  gpx_file_guid: string = '';
  selectedOption: string = 'upload';
  fileErrorMessage: string = '';
  fileName: string = '';
  coordinates: Coordinates | null = null;
  minDate: Date = new Date();
  locationCoord: { lat: number; lng: number } | null = null;
  parsedUserUnitSystem: string = 'metric';
  date_format: string = 'MM/DD/YYYY';

  constructor(
    private router: Router,
    private raceService: RaceService,
    private geocodingService: GeocodingService,
  ) {}

  async ngOnInit() {
    const userUnitSystem = localStorage.getItem(USER_UNIT_SYSTEM);
    const userDateFormat = localStorage.getItem(USER_DATE_FORMAT);
    this.date_format =
      (userDateFormat && JSON.parse(userDateFormat)) || 'MM/DD/YYYY';

    this.parsedUserUnitSystem =
      (userUnitSystem && JSON.parse(userUnitSystem)) || 'metric';

    this.minDate = new Date();
  }

  raceForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    date: new FormControl('', [Validators.required, this.dateNotBeforeToday]),
    gpxFile: new FormControl(null),
    location: new FormControl('', [
      Validators.required,
      Validators.pattern('^[0-9]*$'),
    ]),
    distance: new FormControl(''),
    unit: new FormControl('metric'),
    surface_type: new FormControl(''),
  });

  dateNotBeforeToday(control: AbstractControl): ValidationErrors | null {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const selectedDate = new Date(control.value);

    if (selectedDate < today) {
      return { dateNotBeforeToday: true };
    }
    return null;
  }

  onSubmit() {
    if (this.raceForm.value.distance && this.raceForm.value.unit) {
      const data: RacePayload = {
        name: this.raceForm.value.name ?? '',
        starting_at: this.raceForm.value.date ?? '',
        location: this.raceForm.value.location ?? '',
        distance:
          this.raceForm.value.unit === 'imperial'
            ? distanceConverter(+this.raceForm.value.distance, 'metric')
            : +this.raceForm.value.distance,
        surface_type: this.raceForm.value.surface_type ?? '',
        units: this.raceForm.value.unit ?? '',
        coordinates: {
          lat: this.locationCoord?.lat || 0,
          lon: this.locationCoord?.lng || 0,
        },
      };
      if (this.gpx_file_guid) {
        data.gpx_file_guid = this.gpx_file_guid;
      }

      this.raceService.createRace(data).subscribe((response) => {
        this.router.navigate([
          '/add-new-race/success',
          { title: this.raceForm.get('name')?.value, id: response?.guid },
        ]);
      });
    }
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];

      if (
        file.type !== 'application/gpx+xml' &&
        file.name.split('.').pop()?.toLowerCase() !== 'gpx'
      ) {
        this.fileErrorMessage = 'Please upload a valid GPX file.';
        return;
      }

      const formData = new FormData();
      formData.append('file', file, file.name);

      this.raceService.uploadGpxFile(formData).subscribe((response) => {
        if (!response?.tracks) {
          this.fileErrorMessage = 'Please upload a valid GPX file with tracks.';
          return;
        }

        if (response.start_point.lon && response.start_point.lat) {
          this.geocodingService
            .getPlaceByCoordinates({
              lat: response.start_point.lat,
              lon: response.start_point.lon,
            })
            .subscribe((places) => {
              const formatted_address = places?.length
                ? places[0]?.formatted_address
                : response.waypoints[0]?.properties?.name;
              this.gpx_file_guid = response.guid;
              this.fileName = file.name;
              this.raceForm
                .get('distance')
                ?.setValue(`${response.total_distance}`);
              this.raceForm.get('location')?.setValue(formatted_address);
              this.coordinates = {
                lat: response.start_point.lat,
                lon: response.start_point.lon,
              };
              this.locationCoord = {
                lat: response.start_point.lat,
                lng: response.start_point.lon,
              };
              this.fileErrorMessage = '';
              this.onToggleChange('manual');
            });
        }
      });
    }
  }

  onToggleChange(value: string) {
    this.selectedOption = value;
    this.updateValidators();
  }

  updateValidators() {
    const locationControl = this.raceForm.get('location')!;
    const distanceControl = this.raceForm.get('distance')!;
    const surfaceTypeControl = this.raceForm.get('surface_type')!;

    if (this.selectedOption === 'manual') {
      locationControl.setValidators([Validators.required]);
      distanceControl.setValidators([Validators.required]);
      surfaceTypeControl.setValidators([Validators.required]);
    } else {
      locationControl.clearValidators();
      distanceControl.clearValidators();
      surfaceTypeControl.clearValidators();
    }

    locationControl.updateValueAndValidity();
    distanceControl.updateValueAndValidity();
    surfaceTypeControl.updateValueAndValidity();
  }

  onRemoveFile() {
    this.gpx_file_guid = '';
    this.fileName = '';
  }

  onBackClick() {
    this.router.navigate(['/my-races']);
  }

  onCalendarChange(date: Event) {
    if (this.parsedUserUnitSystem === 'imperial') {
      // @ts-ignore
      document.getElementById('datePicker').value = moment(date.value).format(
        `${this.date_format}, HH:mm a`,
      );
    } else {
      // @ts-ignore
      document.getElementById('datePicker').value = moment(date.value).format(
        `${this.date_format}, HH:mm`,
      );
    }
  }

  onAddressSelected(data: AutocompleteAddress) {
    const { address, coordinates } = data;

    this.locationCoord = coordinates;
    this.raceForm.get('location')?.setValue(`${address}`);
  }
}
