import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DatePipe, NgForOf, NgIf, NgOptimizedImage } from '@angular/common';
import { Router } from '@angular/router';
import { Race } from '../../../../types/models';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../../components/dialog/dialog.component';
import { RaceService } from '../../../services/race/race.service';
import { REMOVE_RACE_WITHOUT_CONFIRMATION } from '../../../../constants';

@Component({
  selector: 'app-list-view',
  standalone: true,
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss'],
  imports: [DatePipe, NgForOf, NgIf, NgOptimizedImage],
})
export class ListViewComponent {
  @Input() races: Race[] = [];
  @Input() time_format: string = 'HH:mm';
  @Output() handleRemoveRace = new EventEmitter<string>();

  private touchStartX: number = 0;
  private swipeContainer: Element | null = null;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private raceService: RaceService,
  ) {}

  onRaceClick(race_id: string | undefined) {
    this.router.navigate(['my-races/race', race_id]);
  }

  onTouchStart(event: TouchEvent) {
    this.touchStartX = event.changedTouches[0].screenX;
  }

  onTouchEnd(event: TouchEvent, race_id: string | undefined) {
    const touchEndX = event.changedTouches[0].screenX;
    this.handleSwipe(touchEndX - this.touchStartX, event.target, race_id);
  }

  openDialog(race_id: string | undefined): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '300px',
      data: {
        headerTitle: 'Remove this event from your calendar?',
        contentText:
          'You can’t undo this action, but you can always add the race back to your calendar at another time.',
        submitBtnText: 'Remove Event',
        onSubmit: () => {
          this.handleRemoveRace.emit(race_id);
          if (this.swipeContainer) {
            this.swipeContainer.classList.remove('swiping-left');
          }
        },
        onCancel: () => {
          if (this.swipeContainer) {
            this.swipeContainer.classList.remove('swiping-left');
          }
        },
        showCheckbox: true,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  handleSwipe(
    swipeDistance: number,
    target: EventTarget | null,
    race_id: string | undefined,
  ) {
    const minDistance = 80;
    const container = (target as HTMLElement).closest('.swipe-container');
    this.swipeContainer = container;
    if (container) {
      if (swipeDistance < minDistance * -1) {
        container.classList.add('swiping-left');
        setTimeout(() => {
          const storageData: string =
            localStorage.getItem(REMOVE_RACE_WITHOUT_CONFIRMATION) || '';
          const removeWithoutConfirmation = storageData === 'true';

          if (!removeWithoutConfirmation) {
            setTimeout(() => {
              this.openDialog(race_id);
            }, 100);
          } else {
            this.handleRemoveRace.emit(race_id);
            container.classList.remove('swiping-left');
          }
        }, 400);
      } else {
        container.classList.remove('swiping-left');
      }
    }
  }
}
