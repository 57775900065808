import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { Location, NgIf, NgStyle } from '@angular/common';
import { RACE_SITE_URL } from '../../../constants';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [MatButton, NgIf, NgStyle],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  @Input() title: string = '';
  @Input() showAdditionalAction: boolean = false;
  @Input() isEditMode: boolean = false;
  @Input() type: string = '';
  @Input() whiteHeader: boolean = false;
  @Input() siteLogo: string | undefined = '';
  @Input() imageHeight: number = 92;
  @Output() handleBackClick = new EventEmitter<void>();
  @Output() handleRightButtonClick = new EventEmitter<void>();

  raceWebsite: string = '';

  constructor(private _location: Location) {}

  ngOnInit() {
    this.raceWebsite = localStorage.getItem(RACE_SITE_URL) || '';
  }

  onBackClick() {
    if (this.handleBackClick) {
      this.handleBackClick.emit();
      return;
    }
    this._location.back();
  }

  onRightButtonClick() {
    this.handleRightButtonClick.emit();
  }

  onRaceWebsiteClick() {
    if (this.raceWebsite) {
      window.open(this.raceWebsite, '_blank');
    }
  }
}
