import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatPrefix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'app-text-field',
  standalone: true,
  imports: [FormsModule, MatFormField, MatIcon, MatInput, MatPrefix],
  templateUrl: './text-field.component.html',
  styleUrl: './text-field.component.scss',
})
export class TextFieldComponent {
  value: string = '';
  @Input() placeholder: string = '';
  @Input() id: string = '';
  @Input() formControlName: string = '';
  @Input() label: string = '';
  @Output() handleChange = new EventEmitter<string>();

  onChange() {
    this.handleChange.emit(this.value);
  }
}
