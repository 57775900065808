<div class="wrapper">
  <div class="header">
    <div>
      <div style="display: flex; align-items: center; gap: 12px">
        <div *ngFor="let type of raceTypes">
        <span
          (click)="selectRace(type?.id || '')"
          [ngStyle]="{
            'color': mainColor,
            'fontWeight': type?.id === currentRace?.guid ? '700' : '400',
            'opacity': type?.id === currentRace?.guid ? '1' : '0.5',
          }"
          style="cursor: pointer; font-size: 16px"
        >
          {{ type?.name }}
        </span>
        </div>
      </div>
    </div>
    <span style="cursor: pointer" (click)="closeDrawer()">
        <img
          src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9Im1hc2swXzU4M18xMTYwIiBzdHlsZT0ibWFzay10eXBlOmFscGhhIiBtYXNrVW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4PSIwIiB5PSIwIiB3aWR0aD0iMzQiIGhlaWdodD0iMzQiPgo8cmVjdCB3aWR0aD0iMzQiIGhlaWdodD0iMzQiIGZpbGw9IiNEOUQ5RDkiLz4KPC9tYXNrPgo8ZyBtYXNrPSJ1cmwoI21hc2swXzU4M18xMTYwKSI+CjxwYXRoIGQ9Ik04LjUzMzQyIDI0Ljg3MTZMNy4xMjg0MiAyMy40NjY2TDE0LjU5NTEgMTZMNy4xMjg0MiA4LjUzMzNMOC41MzM0MiA3LjEyODNMMTYuMDAwMSAxNC41OTVMMjMuNDY2OCA3LjEyODNMMjQuODcxOCA4LjUzMzNMMTcuNDA1MSAxNkwyNC44NzE4IDIzLjQ2NjZMMjMuNDY2OCAyNC44NzE2TDE2LjAwMDEgMTcuNDA1TDguNTMzNDIgMjQuODcxNloiIGZpbGw9IiM2MjcxRkYiLz4KPC9nPgo8L3N2Zz4K"
          alt="Close"
          style="cursor: pointer"
        />
    </span>
  </div>
    <div *ngIf="voiceMode && access_token" class="voice-mode-container">
      <div class="voice-mode-content">
        <button class="voice-mode-start-button" [disabled]="isRecording" (click)="startMicRecording()">
          <img ngSrc="/assets/images/mic-icon-white.svg" alt="progress" height="64" width="64">
        </button>
        <span class="{{isRecording ? 'span-hidden' : '' }}">Press to start</span>
        <p class="voice-mode-text">Ask race event<br>or course questions...</p>
        <!-- Show GIF when isRecording is true -->
        <div *ngIf="isRecording" class="voice-mode-loader">
          <img ngSrc="/assets/images/voice_input.gif" alt="Recording..." height="32" width="368">
        </div>
        <p class="voice-mode-input">{{userMessage}}</p>
      </div>
      <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; gap: 12px">
        <button class="exit-voice-mode-button" (click)="cancelVoiceMode()">Cancel</button>
<!--        <button class="submit-voice-mode-button" [disabled]="!this.speechQueue.length" (click)="stopMicRecording()">Submit</button>-->
      </div>
    </div>
  <div class="chat-wrapper">
    <div #chatContainer class="chat-container" [ngStyle]="{'justify-content': !access_token ? 'end' : 'flex-start' }">
      <div *ngIf="isChatLoading" class="chat-loader-container">
        <div class="custom-loader"></div>
      </div>

      <div *ngIf="!access_token; else chatContent">
        <app-custom-button title="Login" variant="primary" (handleClick)="onLoginClick()" [customBackground]="mainColor"></app-custom-button>
        <span [ngStyle]="{ 'color': mainColor }" style="display: flex; justify-content: center; margin-top: 8px" class="action-button">
          Not registered? <span style="text-decoration: underline; margin-left: 6px; cursor: pointer" (click)="onRegisterClick()">Create an account!</span>
        </span>
      </div>

      <ng-template #chatContent>
        <ng-container *ngFor="let message of messages; let i = index">
          <div *ngIf="i === 0 || !isSameDay(messages[i-1].sent_at, message.sent_at)" class="date">
            {{ message.sent_at | date: 'MMMM d, yyyy' }}
          </div>
          <div *ngIf="message?.link_accounts_options && message?.link_accounts_options?.length; else defaultTemplate" class="fitness-wrapper">
            <mat-chip-set>
              @for (option of message?.link_accounts_options; track option) {
                <mat-chip
                  class="fitness-container"
                  (click)="onFitnessClick(option.name)"
                >
                  <img [src]="option.logo" alt="logo" style="min-height: 25px" />
                </mat-chip>
              }
            </mat-chip-set>
          </div>
          <div *ngIf="message?.table && message?.table!.length >= 1" style="margin: 16px 0">
            <div class="table-responsive">
              <div class="mat-elevation-z8">
                <table mat-table [dataSource]="newDataSource" class="mat-table">

                  <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
                    <th mat-header-cell *matHeaderCellDef
                        [ngStyle]="{
                        'min-width': column === 'Race Km' || column === 'Race Mile' ? '225px' :
                        column === 'Week' ? '150px' : '100px'
                   }">
                      {{ column }}
                    </th>
                    <td mat-cell *matCellDef="let element"> {{ element[column] }} </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
              </div>
            </div>
          </div>
          <div *ngIf="message.is_elevation && message.gpx && message.widget">
            <div class="chat-elevation">
              <app-elevation-profile
                [coordinates]="message.gpx.tracks[0].geometry.coordinates"
                [units]="race_units"
                [showSelection]="false"
              ></app-elevation-profile>
            </div>
          </div>
          <ng-template #defaultTemplate>
            <div class="chat-message" [ngClass]="{'ai-message': message.is_system, 'user-message': !message.is_system}">
              <div
                [ngClass]="{'message-content': !message?.is_options_message && !message?.widget && !message.is_map && (message?.text || message?.label), 'option-message': message?.is_options_message, 'map-message': message?.is_map}"
                style="overflow-y: auto"
              >
                <p *ngIf="message?.label && message.text" style="font-weight: 700">{{ message?.label }}</p>
                <div [innerHTML]="message.text"></div>
                <div *ngIf="message?.options?.length && message?.is_options_message" class="link-container">
                  <mat-chip-set class="chip-container">
                    @for (option of message?.options; track option) {
                      <mat-chip class="option-container" (click)="onOptionClick(option)" [disabled]="option === 'Choose This Segment' && !segment_coordinates">
                        <span>{{ option }}</span>
                      </mat-chip>
                    }
                  </mat-chip-set>
                </div>
                <div *ngIf="message?.gearOptions?.length" class="gear-options-container">
                  @for (option of message?.gearOptions; track option) {
                    <div class="message-content" style="min-width: 180px; min-height: 100px; display: flex; flex-direction: column; justify-content: space-between; padding: 0">
                      <div *ngIf="option?.image">
                        <img [src]="option?.image" style="width: 180px; height: 185px; border-top-left-radius: 15px; border-top-right-radius: 15px; object-fit: cover" [alt]="option?.text" />
                      </div>
                      <div style="padding: 10px">
                        <p style="font-weight: 700; margin: 2px 0; font-size: 16px">{{ option.text }}</p>
                        <div class="link-container">
                          <a [href]='option?.link?.link' class="link" target="_blank" rel="noopener noreferrer">
                            {{ option?.link?.text }}
                            <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNSAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9Im1hc2swXzY3M18xMjA1IiBzdHlsZT0ibWFzay10eXBlOmFscGhhIiBtYXNrVW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4PSIwIiB5PSIwIiB3aWR0aD0iMjUiIGhlaWdodD0iMjUiPgo8cmVjdCB4PSIwLjI2MjY5NSIgeT0iMC4zNTU5NTciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0iI0Q5RDlEOSIvPgo8L21hc2s+CjxnIG1hc2s9InVybCgjbWFzazBfNjczXzEyMDUpIj4KPHBhdGggZD0iTTE2Ljg4OTcgMTMuMTA2SDQuNzYyN1YxMS42MDZIMTYuODg5N0wxMS4xOTM0IDUuOTA5NzFMMTIuMjYyNyA0Ljg1NTk2TDE5Ljc2MjcgMTIuMzU2TDEyLjI2MjcgMTkuODU2TDExLjE5MzQgMTguODAyMkwxNi44ODk3IDEzLjEwNloiIGZpbGw9IiM2MjcxRkYiLz4KPC9nPgo8L3N2Zz4K" alt="Arrow forward" />
                          </a>
                        </div>
                      </div>
                    </div>
                  }
                </div>
                <div *ngIf="message.is_map && path && segment_tracks && !message.gpx">
                  <div style="position: relative; overflow: hidden">
                    <app-routes-map [paths]="path"></app-routes-map>
                    <div style="position: absolute; bottom: 10px">
                      <app-route-swiper [routes]="segment_tracks" [units]="race_units"></app-route-swiper>
                    </div>
                  </div>
                </div>
                <div *ngIf="message.is_map && message.gpx && message.widget">
                  <div style="position: relative; overflow: hidden">
                    <app-interactive-map [gpx]="message.gpx" [widget]="message.widget" [centerCoordinate]="message.center_coordinate"></app-interactive-map>
                  </div>
                </div>
                <div *ngIf="message.loading" class="three-dots-loader">
                  <span></span><span></span><span></span>
                </div>
              </div>
            </div>
          </ng-template>
          <div *ngIf="message?.widget">
            <div *ngIf="message.widget === 'SEGMENT_PICKER' && elevationsData?.length">
              <app-elevation-profile [coordinates]="elevationsData" [showSelection]="true" (handleSegmentSelected)="onSegmentSelected($event)" [units]="race_units"></app-elevation-profile>
            </div>
            <div *ngIf="message.widget === 'RADIUS_PICKER' && radius_center_point" style="margin-bottom: 10px">
              <app-map-with-radius [startPoint]="radius_center_point || start_point" (handleSelectRadius)="onRadiusSelect($event)" [userUnit]="race_units" [mainColor]="mainColor"></app-map-with-radius>
            </div>
          </div>
        </ng-container>
      </ng-template>
    </div>
    <div class="input-container">
      <input
        class="chat-input"
        [disabled]="!access_token"
        [(ngModel)]="userMessage"
        placeholder="Ask a follow up question"
        (keydown.enter)="sendMessage()"
      >
        <div class="btn-container">
            <div *ngIf="!userMessage.trim() && !isRecording" style="display: flex; align-items: center">
                <mat-menu
                        yPosition="above"
                        #menu="matMenu"
                >
                    <ng-template matMenuContent>
                        <div class="dialog-content">
                            <label for="microphoneSelect">Select Microphone:</label>
                            <select
                                    id="microphoneSelect"
                                    [(ngModel)]="selectedDeviceId"
                                    (change)="selectDevice(selectedDeviceId)"
                                    (click) = "$event.stopPropagation()"
                            >
                                <option *ngFor="let device of audioDevices" [value]="device.deviceId">
                                    {{ device.label || 'Unknown Microphone' }}
                                </option>
                            </select>
                        </div>
                    </ng-template>
                </mat-menu>
                <button [matMenuTriggerFor]="menu" #menuTrigger class="mic-settings">
                    <mat-icon class="btn-icon">settings</mat-icon>
                </button>
                <button (click)="startMicRecording()" class="send-btn" style="background: none">
                  <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9Im1hc2swXzkwNV81MTgiIHN0eWxlPSJtYXNrLXR5cGU6YWxwaGEiIG1hc2tVbml0cz0idXNlclNwYWNlT25Vc2UiIHg9IjAiIHk9IjAiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+CjxyZWN0IHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0iI0Q5RDlEOSIvPgo8L21hc2s+CjxnIG1hc2s9InVybCgjbWFzazBfOTA1XzUxOCkiPgo8cGF0aCBkPSJNMTIgMTMuNUMxMS4zMDEzIDEzLjUgMTAuNzEgMTMuMjU4IDEwLjIyNiAxMi43NzRDOS43NDIgMTIuMjkgOS41IDExLjY5ODcgOS41IDExVjVDOS41IDQuMzAxMzMgOS43NDIgMy43MSAxMC4yMjYgMy4yMjZDMTAuNzEgMi43NDIgMTEuMzAxMyAyLjUgMTIgMi41QzEyLjY5ODcgMi41IDEzLjI5IDIuNzQyIDEzLjc3NCAzLjIyNkMxNC4yNTggMy43MSAxNC41IDQuMzAxMzMgMTQuNSA1VjExQzE0LjUgMTEuNjk4NyAxNC4yNTggMTIuMjkgMTMuNzc0IDEyLjc3NEMxMy4yOSAxMy4yNTggMTIuNjk4NyAxMy41IDEyIDEzLjVaTTExLjI1IDIwLjc1VjE3LjQ1MzhDOS42IDE3LjI2NTMgOC4yMjkxNyAxNi41NTc2IDcuMTM3NSAxNS4zMzA3QzYuMDQ1ODMgMTQuMTAzNyA1LjUgMTIuNjYwMiA1LjUgMTFIN0M3IDEyLjM4MzMgNy40ODc1IDEzLjU2MjUgOC40NjI1IDE0LjUzNzVDOS40Mzc1IDE1LjUxMjUgMTAuNjE2NyAxNiAxMiAxNkMxMy4zODMzIDE2IDE0LjU2MjUgMTUuNTEyNSAxNS41Mzc1IDE0LjUzNzVDMTYuNTEyNSAxMy41NjI1IDE3IDEyLjM4MzMgMTcgMTFIMTguNUMxOC41IDEyLjY2MDIgMTcuOTU0MiAxNC4xMDM3IDE2Ljg2MjUgMTUuMzMwN0MxNS43NzA4IDE2LjU1NzYgMTQuNCAxNy4yNjUzIDEyLjc1IDE3LjQ1MzhWMjAuNzVIMTEuMjVaIiBmaWxsPSIjN0U3RTdFIi8+CjwvZz4KPC9zdmc+Cg==" alt="Mic">
                </button>
            </div>
            <button *ngIf="isRecording" (click)="stopMicRecording()" class="mic-btn-active">
                <mat-icon class="btn-icon">mic</mat-icon>
            </button>
            <button *ngIf="userMessage.trim() && !isRecording" (click)="sendMessage()" class="send-btn"
                    [disabled]="!userMessage.trim()" [ngStyle]="{ 'background': mainColor || '#6271FF' }">
                <mat-icon class="btn-icon">arrow_upward</mat-icon>
            </button>
        </div>
    </div>
    <div class="additional-prompts" [ngStyle]="{ 'background': mainColor || 'linear-gradient(to bottom, #4C57BD, #6272FE)' }" style="width: 100%">
      <app-prompts-list [disabled]="!access_token" [prompts]="prompts" (handlePromptClick)="onPromptClick($event)" [prefix]="'Additional prompts:'" [mainColor]="mainColor"></app-prompts-list>
    </div>
  </div>
</div>
