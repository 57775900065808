import { Component, OnInit } from '@angular/core';
import { MatFormField, MatOption, MatSelect } from '@angular/material/select';
import { MatDivider } from '@angular/material/divider';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { AsyncPipe, NgForOf, NgIf, Location, NgClass } from '@angular/common';
import { MatCard } from '@angular/material/card';
import { HeaderComponent } from '../../../components/header/header.component';
import { UserService } from '../../../services/user/user.service';
import { Router } from '@angular/router';
import { SpinnerServiceService } from '../../../services/spinner-service/spinner-service.service';
import { CustomButtonComponent } from '../../../components/custom-button/custom-button.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ChatService } from '../../../services/chat/chat.service';
import { DialogComponent } from '../../../components/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { User, UserSettings } from '../../../../types/models';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-user-ai-settings',
  standalone: true,
  imports: [
    MatSelect,
    MatOption,
    MatDivider,
    MatSlideToggle,
    FormsModule,
    NgForOf,
    MatCard,
    AsyncPipe,
    HeaderComponent,
    NgIf,
    CustomButtonComponent,
    MatSlideToggleModule,
    MatFormField,
    MatProgressSpinner,
    NgClass,
  ],
  templateUrl: './user-ai-settings.component.html',
  styleUrl: './user-ai-settings.component.scss',
})
export class UserAiSettingsComponent implements OnInit {
  voices: string[] = ['Jesse', 'Alex', 'Sam'];
  languages: string[] = ['Auto Detect', 'English', 'Spanish', 'French'];
  detailLevels: string[] = ['Low', 'Medium', 'High'];
  initialFormValues: any;
  selectedVoice = this.voices[0];
  selectedLanguage = this.languages[0];
  selectedDetailLevel = this.detailLevels[1];
  memoryEnabled = true;
  user: User | undefined = undefined;
  isLoading: boolean = false;

  constructor(
    private userService: UserService,
    private router: Router,
    public spinnerService: SpinnerServiceService,
    private location: Location,
    private chatService: ChatService,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.userService.getUser().subscribe((response) => {
      this.user = response;
      this.memoryEnabled = response?.settings.save_chat_history;

      this.initialFormValues = this.memoryEnabled;
      this.isLoading = false;
    });
  }

  normalizeValues(values: any): any {
    if (values) {
      return Object.keys(values).reduce((acc, key) => {
        acc[key] = String(values[key]);
        return acc;
      }, {} as any);
    }
  }

  hasFormChanged(): boolean {
    const normalizedInitialValues = this.normalizeValues(
      this.initialFormValues,
    );
    const normalizedCurrentValues = this.normalizeValues(this.memoryEnabled);
    return (
      JSON.stringify(normalizedInitialValues) !==
      JSON.stringify(normalizedCurrentValues)
    );
  }

  onBackClick() {
    this.location.back();
  }

  onSubmit() {
    if (this.hasFormChanged()) {
      const data: UserSettings = {
        settings: {
          units: this.user?.settings.units || 'metric',
          date_format: this.user?.settings.date_format || 'MM/DD/YYYY',
          save_chat_history: this.memoryEnabled,
        },
      };
      this.userService.updateUser(data).subscribe(() => {
        this.router.navigate(['/profile']);
      });
    }
  }

  selectIsOpen() {}

  openDialog() {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '300px',
      data: {
        headerTitle: 'Delete chat history?',
        contentText:
          'Are you sure you want to delete chat history? This action cannot be undone.',
        submitBtnText: 'Submit',
        onSubmit: () => {
          this.chatService.deleteChatHistory().subscribe();
        },
        onCancel: () => {},
        showCheckbox: false,
      },
    });
  }

  onDeleteChatHistoryClick() {
    this.openDialog();
  }

  onToggle() {
    console.log('Toggle switch state:', this.memoryEnabled);
  }
}
