import { Component } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-social-login',
  standalone: true,
  imports: [MatIcon],
  templateUrl: './social-login.component.html',
  styleUrl: './social-login.component.scss',
})
export class SocialLoginComponent {
  constructor(private readonly keycloakService: KeycloakService) {}

  loginWithProvider(provider: string) {
    this.keycloakService.login({
      idpHint: provider,
    });
  }
}
