import { Component, OnInit } from '@angular/core';
import { HeaderComponent } from '../../components/header/header.component';
import { NgOptimizedImage } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomButtonComponent } from '../../components/custom-button/custom-button.component';

@Component({
  selector: 'app-success-race-create',
  standalone: true,
  imports: [HeaderComponent, NgOptimizedImage, CustomButtonComponent],
  templateUrl: './success-race-create.component.html',
  styleUrl: './success-race-create.component.scss',
})
export class SuccessRaceCreateComponent implements OnInit {
  title: string = '';
  race_id: string = '';
  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.route.data.subscribe((data) => {
      console.log('data', data);
    });
  }

  ngOnInit(): void {
    this.title = this.route.snapshot.paramMap.get('title') || '';
    this.race_id = this.route.snapshot.paramMap.get('id') || '';
  }

  onViewCalendarCLick() {
    this.router.navigate(['/my-races', { calendarView: 1 }]);
  }

  onViewRaceDetails() {
    this.router.navigate(['my-races/race', this.race_id]);
  }

  onBackClick() {
    this.router.navigate(['/my-races']);
  }
}
