import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { CustomButtonComponent } from '../../../components/custom-button/custom-button.component';
import { HeaderComponent } from '../../../components/header/header.component';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { UserService } from '../../../services/user/user.service';
import { Router } from '@angular/router';
import { SpinnerServiceService } from '../../../services/spinner-service/spinner-service.service';
import { EMAIL_REGEX } from '../../../../constants';
import { User, UserNameInfo } from '../../../../types/models';
import { KeycloakService } from 'keycloak-angular';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-user-email',
  standalone: true,
  imports: [
    AsyncPipe,
    CustomButtonComponent,
    HeaderComponent,
    MatFormField,
    MatInput,
    NgIf,
    ReactiveFormsModule,
    MatProgressSpinner,
    NgClass,
  ],
  templateUrl: './user-email.component.html',
  styleUrl: './user-email.component.scss',
})
export class UserEmailComponent implements OnInit {
  user: User | undefined = undefined;
  initialFormValues: any;
  isLoading: boolean = false;

  constructor(
    private userService: UserService,
    private router: Router,
    public spinnerService: SpinnerServiceService,
    private readonly keycloakService: KeycloakService,
  ) {}

  emailForm = new FormGroup({
    email: new FormControl('', [Validators.pattern(EMAIL_REGEX)]),
  });

  async ngOnInit() {
    this.isLoading = true;
    this.userService.getUser().subscribe((response) => {
      this.user = response;

      this.emailForm.get('email')?.setValue(response?.email || '');

      this.initialFormValues = this.emailForm.value;
      this.isLoading = false;
    });
  }

  hasFormChanged(): boolean {
    return (
      JSON.stringify(this.initialFormValues) !==
      JSON.stringify(this.emailForm.value)
    );
  }

  onBackClick() {
    this.router.navigate(['/profile']);
  }

  updatePassword() {
    this.keycloakService.login({
      action: 'UPDATE_PASSWORD',
    });
  }

  onSubmit() {
    if (this.emailForm.valid && this.hasFormChanged()) {
      const data: UserNameInfo = {
        first_name: this.user?.first_name ?? '',
        last_name: this.user?.last_name ?? '',
        display_name: this.user?.display_name ?? '',
        email: this.emailForm.value.email ?? '',
      };
      this.userService.updateUser(data).subscribe(() => {
        this.router.navigate(['/profile']);
      });
    }
  }
}
