import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatList, MatListItem } from '@angular/material/list';
import { MatIcon } from '@angular/material/icon';
import { MatDivider } from '@angular/material/divider';
import { MatLine } from '@angular/material/core';
import { KeycloakService } from 'keycloak-angular';
import { UserService } from '../../services/user/user.service';
import { User } from '../../../types/models';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../components/dialog/dialog.component';
import { USER_DATE_FORMAT, USER_UNIT_SYSTEM } from '../../../constants';
import { ScreenResizeService } from '../../services/screenResive/screen-resize.service';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
  imports: [
    MatCardModule,
    MatListItem,
    MatList,
    MatIcon,
    MatDivider,
    MatLine,
    NgIf,
  ],
  standalone: true,
})
export class ProfileComponent implements OnInit {
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLButtonElement>;
  user: User | undefined = undefined;
  avatar: string = '';
  isMobile: boolean = false;

  constructor(
    private readonly keycloakService: KeycloakService,
    private userService: UserService,
    private router: Router,
    public dialog: MatDialog,
    private screenSizeService: ScreenResizeService,
  ) {}

  async ngOnInit() {
    const isTokenExpired = this.keycloakService.isTokenExpired();

    if (isTokenExpired) {
      await this.keycloakService.login();
    }

    this.screenSizeService.getIsMobile().subscribe((isMobile) => {
      this.isMobile = isMobile;
    });

    this.userService.getUser().subscribe({
      next: (response) => {
        this.user = response;
        this.avatar = response.picture;
        localStorage.setItem(
          USER_UNIT_SYSTEM,
          JSON.stringify(response.settings.units),
        );
        localStorage.setItem(
          USER_DATE_FORMAT,
          JSON.stringify(response.settings.date_format),
        );
      },
      error: () => {
        this.router.navigate(['/error-page']);
      },
    });
  }

  openDialog() {
    this.dialog.open(DialogComponent, {
      width: '300px',
      data: {
        headerTitle: 'Remove account?',
        contentText:
          'Are you sure you want to delete your account? This action cannot be undone.',
        submitBtnText: 'Remove Account',
        onSubmit: () => {
          this.userService.deleteUser().subscribe(() => {
            this.onLogoutClick();
          });
        },
        onCancel: () => {},
        showCheckbox: false,
      },
    });
  }

  onImageClick() {
    this.fileInput.nativeElement.click();
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];

      const formData = new FormData();
      formData.append('file', file, file.name);
      this.userService.uploadUserAvatar(formData).subscribe((response) => {
        this.avatar = response.url;
      });
    }
  }

  onSectionClick(section: string) {
    this.router.navigate([`/profile/${section}`]);
  }

  onLogoutClick() {
    localStorage.removeItem(USER_UNIT_SYSTEM);
    this.keycloakService.logout();
  }

  onDeleteAccountClick() {
    this.openDialog();
  }

  onSupportClick() {
    window.location.href = 'mailto:support@neurun.com';
  }
}
