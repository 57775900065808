import { Component, OnInit } from '@angular/core';
import { SocialLoginComponent } from '../../components/social-login/social-login.component';
import { MatDivider } from '@angular/material/divider';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { CustomButtonComponent } from '../../components/custom-button/custom-button.component';
import { Router } from '@angular/router';
import { AuthHeaderComponent } from '../../components/auth-header/auth-header.component';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { KeycloakAuth } from '../../services/keycloak/keycloak.service';
import { NgIf } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    SocialLoginComponent,
    MatDivider,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    CustomButtonComponent,
    AuthHeaderComponent,
    NgIf,
  ],
  // templateUrl: './login.component.html',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit {
  public isLoggedIn = false;
  public userProfile: KeycloakProfile | null = null;
  loginUrl: string = '';

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
  });

  constructor(
    private router: Router,
    private readonly keycloakService: KeycloakService,
    private readonly authKeycloak: KeycloakAuth,
    protected sanitizer: DomSanitizer,
  ) {}

  async ngOnInit() {
    const isLogged = await this.keycloakService.isLoggedIn();

    if (isLogged) {
      this.router.navigate(['/profile']);
    } else {
      this.keycloakService.login();
    }
    // const isLogged = await this.keycloakService.isLoggedIn();
    // const url = this.keycloakService.getKeycloakInstance().createLoginUrl();
    // console.log('isLogged', url);
    // this.loginUrl = url
    // this.isLoggedIn = isLogged
    // if (this.isLoggedIn) {
    //   this.userProfile = await this.keycloakService.loadUserProfile();
    // }
  }

  onSubmit() {
    const email = this.loginForm.value.email ?? '';
    const password = this.loginForm.value.password ?? '';
    this.authKeycloak.login(email, password).subscribe(async (result) => {
      const ks = await this.keycloakService.init({
        config: {
          url: environment.keycloakUrl,
          realm: environment.keycloakRealm,
          clientId: environment.keycloakClientId,
        },
        initOptions: {
          token: result.access_token,
          // refreshToken: result?.refresh_token,
          flow: 'implicit',
          checkLoginIframe: false,
          onLoad: 'check-sso',
          // silentCheckSsoRedirectUri:
          //   window.location.origin + '/assets/silent-check-sso.html',
        },
      });
    });
    // this.keycloakService.login({idpHint: 'google'});
  }

  onRegisterClick() {
    this.router.navigate(['/register']);
  }

  onForgotPasswordClick() {
    this.router.navigate(['/forgot-password']);
  }
}
