<div class="container">
  <span class="header-title">{{ data.headerTitle }}</span>
  <div class="content">
    <p>{{ data.contentText }}</p>
  </div>
  <div class="dialog-actions">
    <app-custom-button *ngIf="showSubmitButton" [title]="data.submitBtnText" variant="primary" (handleClick)="onSubmit()"></app-custom-button>
    <div style="margin-top: 16px; margin-bottom: 16px">
      <app-custom-button title="Cancel" variant="outlined" (handleClick)="onCancel()"></app-custom-button>
    </div>
  </div>
  <div *ngIf="data.showCheckbox">
    <section>
      <mat-checkbox class="custom-checkbox" (change)="handleCheckboxChange($event)">Don’t warn me again</mat-checkbox>
    </section>
  </div>
</div>
