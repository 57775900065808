<div>
  <div class="prompts-wrapper">
    <swiper-container class="mySwiper" space-between="12" slides-per-view="auto" navigation="true">
      <swiper-slide *ngIf="prefix">
        <span style="font-weight: 400; font-size: 16px; color: #FFFFFF; white-space: nowrap; line-height: 22px">
          {{ prefix }}
        </span>
      </swiper-slide>
      <swiper-slide *ngFor="let prompt of prompts">
        <div
          class="prompt-container"
          (click)="onClick(prompt)"
          [ngStyle]="{
            'opacity': disabled || prompt === 'Replicate uphill segments of the race near me' ? 0.5 : 1,
            'cursor': disabled || prompt === 'Replicate uphill segments of the race near me' ? 'not-allowed' : 'pointer',
            'color': mainColor
          }"
        >
          <span class="prompt-text">{{ prompt }}</span>
        </div>
      </swiper-slide>
    </swiper-container>
  </div>
</div>
