import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import {
  ApiResponse,
  Event,
  GetRacePayload,
  GpxFile,
  Race,
  RaceGoal,
  RaceGoalPayload,
  RacePayload,
  Weather,
} from '../../../types/models';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RaceService {
  // private apiUrl: string = 'https://poc.neurun.com/api';
  private apiUrl: string = environment.baseUrl;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient) {}

  getRaces(data?: GetRacePayload): Observable<ApiResponse<Race[]>> {
    let params = new HttpParams();

    if (data?.startDate) {
      params = params.set('startDate', data?.startDate);
    }

    if (data?.endDate) {
      params = params.set('endDate', data?.endDate);
    }

    if (data?.search && data?.search.length >= 1) {
      params = params.set('s', data?.search);
    }
    const url = `${this.apiUrl}/race/search`;
    return this.http
      .get<ApiResponse<Race[]>>(url, { params })
      .pipe(catchError(this.handleError));
  }

  createRace(data: RacePayload): Observable<Race> {
    const url = `${this.apiUrl}/race`;
    return this.http
      .post<Race>(url, data, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getRaceById(race_guid: string): Observable<Race> {
    const url = `${this.apiUrl}/race/guid/${race_guid}`;
    return this.http.get<Race>(url);
  }

  uploadGpxFile(file: FormData): Observable<GpxFile> {
    const url = `${this.apiUrl}/gpx/upload`;
    return this.http
      .post<GpxFile>(url, file)
      .pipe(catchError(this.handleError));
  }

  getGpxData(race_guid: string): Observable<GpxFile> {
    const url = `${this.apiUrl}/race/gpx/${race_guid}`;
    return this.http.get<GpxFile>(url).pipe(catchError(this.handleError));
  }

  getRaceWeather(race_guid: string): Observable<Weather> {
    const url = `${this.apiUrl}/race/weather/${race_guid}`;
    return this.http.get<Weather>(url).pipe(catchError(this.handleError));
  }

  getRaceGoal(race_guid: string): Observable<RaceGoal> {
    const url = `${this.apiUrl}/race/goal/${race_guid}`;
    return this.http.get<RaceGoal>(url).pipe(catchError(this.handleError));
  }

  createRaceGoal(
    race_guid: string,
    data: RaceGoalPayload,
  ): Observable<RaceGoal> {
    const url = `${this.apiUrl}/race/goal/${race_guid}`;
    return this.http
      .post<RaceGoal>(url, data)
      .pipe(catchError(this.handleError));
  }

  updateRaceGoal(
    race_goal_guid: string,
    data: RaceGoalPayload,
  ): Observable<RaceGoal> {
    const url = `${this.apiUrl}/race/goal/${race_goal_guid}`;
    return this.http
      .put<RaceGoal>(url, data)
      .pipe(catchError(this.handleError));
  }

  deleteRace(race_guid: string) {
    const url = `${this.apiUrl}/race/${race_guid}`;
    return this.http.delete(url).pipe(catchError(this.handleError));
  }

  getEventRaces(event_guid: string): Observable<Event> {
    const url = `${this.apiUrl}/race/event/guid/${event_guid}`;

    return this.http.get<Event>(url).pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = 'An unknown error occurred!';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(() => errorMessage);
  }
}
