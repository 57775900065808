import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MapGeocoder } from '@angular/google-maps';
import { AutocompleteAddress } from '../../../types/models';

export interface PlaceSearchResult {
  address: string;
  location?: google.maps.LatLng;
  name?: string;
}

@Component({
  selector: 'app-map-autocomplete',
  standalone: true,
  imports: [FormsModule, MatFormField, MatInput, ReactiveFormsModule],
  templateUrl: './map-autocomplete.component.html',
  styleUrl: './map-autocomplete.component.scss',
})
export class MapAutocompleteComponent implements AfterViewInit {
  @Input() placeholder: string = '';
  @Input() initialCoordinates: { lat: number; lng: number } | null = {
    lat: 41.437098,
    lng: 2.165957,
  };
  @Output() addressSelected = new EventEmitter<AutocompleteAddress>();
  // @ts-ignore
  @ViewChild('autocomplete') autocompleteElement: ElementRef;

  constructor(
    private ngZone: NgZone,
    private geocoder: MapGeocoder,
  ) {}

  ngAfterViewInit(): void {
    this.initAutocomplete();

    if (this.initialCoordinates) {
      this.geocoder
        .geocode({
          location: this.initialCoordinates,
        })
        .subscribe((response) => {
          if (response?.results?.length) {
            this.autocompleteElement.nativeElement.value =
              response?.results[0].formatted_address;
          }
        });
    }
  }

  private initAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(
      this.autocompleteElement.nativeElement,
      {},
    );

    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        const place = autocomplete.getPlace();
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }
        //@ts-ignore
        const address = place.formatted_address;
        const lat = place.geometry.location?.lat();
        const lng = place.geometry.location?.lng();

        if (address && lat && lng) {
          this.addressSelected.emit({ address, coordinates: { lat, lng } });
        }
      });
    });
  }
}
