import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  DatePipe,
  NgClass,
  NgForOf,
  NgIf,
  NgOptimizedImage,
  NgStyle,
  TitleCasePipe,
} from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PromptsListComponent } from '../../components/prompts-list/prompts-list.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ChatService } from '../../services/chat/chat.service';
import {
  ChatMessage,
  ChatMessageLink,
  GpxWaypoints,
  MappedChatMessage,
  Race,
  RaceRadiusData,
  RawSttMessage,
  SegmentTracks,
  SttMessage,
  Track,
  TrackCoordinates,
} from '../../../types/models';
import {
  AI_REQUEST_TIMEOUT,
  AI_REQUESTS,
  CHOOSE_SEGMENT_OPTION,
  EVENT_RACES,
  INTERACTIVE_WIDGETS,
  LINK_ACCOUNTS_IMAGES,
  MAIN_COLOR,
  RACE_TYPES,
  SELECTED_RACE_ID,
  SET_RADIUS_OPTION,
  SHOW_MAP_OPTION,
  WIDGET_PROMPTS_LIST,
} from '../../../constants';
import { RaceService } from '../../services/race/race.service';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource,
} from '@angular/material/table';
import { GoogleMapsServiceService } from '../../services/google-maps-service/google-maps-service.service';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { ElevationProfileComponent } from '../../components/elevation-profile/elevation-profile.component';
import { MapWithRadiusComponent } from '../../components/map-with-radius/map-with-radius.component';
import { MatChip, MatChipSet } from '@angular/material/chips';
import { RouteSwiperComponent } from '../../components/route-swiper/route-swiper.component';
import { RoutesMapComponent } from '../../components/routes-map/routes-map.component';
import { OidcServiceService } from '../../services/oidcService/oidc-service.service';
import { InteractiveMapComponent } from '../../components/interactive-map/interactive-map.component';
import { KeycloakService } from 'keycloak-angular';
import { CustomButtonComponent } from '../../components/custom-button/custom-button.component';
import { clearAsyncInterval, setAsyncInterval } from '../../helpers/intervals';
import { downSampleBuffer, pcmEncode } from '../../helpers/audioStream';
import { DialogComponent } from '../../components/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {
  MatMenu,
  MatMenuContent,
  MatMenuTrigger,
} from '@angular/material/menu';
import { ScreenResizeService } from '../../services/screenResive/screen-resize.service';
import { jwtDecode } from 'jwt-decode';

type Coordinates = {
  lat: number;
  lng: number;
};

type MappedPathOutput = {
  lat: number;
  lng: number;
  distance?: number;
  elevationGain?: number;
  totalSimilarity?: number;
};

@Component({
  selector: 'app-ai-chat',
  standalone: true,
  imports: [
    NgIf,
    MatIcon,
    ReactiveFormsModule,
    FormsModule,
    PromptsListComponent,
    MatProgressSpinner,
    DatePipe,
    ElevationProfileComponent,
    MapWithRadiusComponent,
    MatCell,
    MatCellDef,
    MatChip,
    MatChipSet,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatTable,
    NgForOf,
    RouteSwiperComponent,
    RoutesMapComponent,
    MatColumnDef,
    NgStyle,
    MatHeaderCellDef,
    NgClass,
    InteractiveMapComponent,
    CustomButtonComponent,
    TitleCasePipe,
    MatMenuTrigger,
    MatMenu,
    MatMenuContent,
    NgOptimizedImage,
  ],
  templateUrl: './ai-chat.component.html',
  styleUrl: './ai-chat.component.scss',
})
export class AiChatComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('chatContainer') chatContainer!: ElementRef;
  userMessage: string = '';
  prompts: string[] = WIDGET_PROMPTS_LIST;
  raceId: string = '';
  isChatLoading: boolean = false;
  messages: MappedChatMessage[] = [];
  elevationsData: TrackCoordinates[] | null = [];
  race: Race | null = null;
  start_point: Coordinates | null = null;
  race_units: string = '';
  radius_center_point: Coordinates | null = null;
  displayedColumns: string[] = [
    'month',
    'runs_count',
    'total_distance',
    'longest_run',
  ];
  newDataSource: MatTableDataSource<{ [key: string]: string }>;
  isMessageLoading: boolean = false;
  segment_tracks: SegmentTracks[] | null = [];
  path: Coordinates[][] | null = null;
  isChatHistoryEmpty: boolean = false;
  AIRequestError: boolean = false;
  segment_coordinates: {
    startPointIndex: number;
    endPointIndex: number;
  } | null = null;
  race_radius: number = 0;
  units: string = 'imperial';
  segment_distance: number = 0;
  prompt: string = '';
  voiceMode: boolean = false;
  hasRunkeeperTokenProcessed = false;
  races: Race[] = [];
  isSpeechProcessing: boolean = false;
  isRecording: boolean = false;
  speechStartTime: number = 0;
  deviceStream: MediaStream | null = null;
  speechQueue: { chunk: Float32Array; time: number }[] = [];
  speechQueueProcessingInterval: number = 0;
  sttTimeout: number = 1.0;
  submitSpeechTimeout: number = 0;
  audioContext: AudioContext | null = null;
  audioWorkletNode: AudioWorkletNode | null = null;
  micStream: MediaStreamAudioSourceNode | null = null;
  isMicSelectorOpened: boolean = false;
  audioDevices: MediaDeviceInfo[] = [];
  selectedDeviceId: string | null = null;
  raceTypes: { id: string; name: string; display_order: number }[] = [];
  currentRace: Race | null = null;
  isMobile: boolean = false;
  access_token: string = '';
  mainColor: string = '';

  private boundOnRunkeeperToken: (event: StorageEvent) => void;
  private loadingMessageTimeout: any = null;

  constructor(
    private keycloakService: KeycloakService,
    private chatService: ChatService,
    private sanitizer: DomSanitizer,
    private raceService: RaceService,
    private googleMapsService: GoogleMapsServiceService,
    private readonly oidcService: OidcServiceService,
    private screenSizeService: ScreenResizeService,
    public dialog: MatDialog,
  ) {
    this.newDataSource = new MatTableDataSource<{ [key: string]: string }>([]);
    this.boundOnRunkeeperToken = this.onRunkeeperToken.bind(this);
  }

  async ngOnInit() {
    const savedPrompt = localStorage.getItem('prompt');
    const keycloakToken = localStorage.getItem('keycloak-token');
    // const parsedKeycloakToken = keycloakToken ? JSON.parse(keycloakToken) : null;
    const selectedRaceId = localStorage.getItem(SELECTED_RACE_ID);
    const eventRaces = localStorage.getItem(EVENT_RACES);
    const raceTypes = localStorage.getItem(RACE_TYPES);
    const voiceMode = localStorage.getItem('voiceMode');
    const mainColor = localStorage.getItem(MAIN_COLOR);
    const parsedVoiceMode = voiceMode ? JSON.parse(voiceMode) : null;
    const parsedRaces = eventRaces ? JSON.parse(eventRaces) : null;
    const parsedRaceTypes = raceTypes ? JSON.parse(raceTypes) : null;

    this.screenSizeService.getIsMobile().subscribe((isMobile) => {
      this.isMobile = isMobile;
    });

    if (keycloakToken) {
      this.access_token = keycloakToken;
    }

    if (savedPrompt) {
      this.prompt = savedPrompt;
      localStorage.removeItem('prompt');
    }

    if (selectedRaceId) {
      this.raceId = selectedRaceId;
      localStorage.removeItem(SELECTED_RACE_ID);
    }

    if (parsedRaces) {
      this.races = parsedRaces;
      localStorage.removeItem(EVENT_RACES);
    }

    if (parsedRaceTypes) {
      this.raceTypes = parsedRaceTypes;
      localStorage.removeItem(RACE_TYPES);
    }

    if (parsedVoiceMode) {
      this.races = parsedVoiceMode;
      localStorage.removeItem('voiceMode');
    }

    if (mainColor) {
      this.mainColor = mainColor;
      localStorage.removeItem(MAIN_COLOR);
    }

    window.addEventListener('storage', this.boundOnRunkeeperToken);
    window.addEventListener('message', (event) => {
      // Validate the origin for security
      // if (event.origin !== 'http://expected-parent-origin.com') {
      //   console.warn('Untrusted origin:', event.origin);
      //   return;
      // }
      // Check the type of message
      if (event.data.type === 'prompt') {
        const receivedData = event.data.payload;
        localStorage.setItem('prompt', receivedData);
        this.prompt = receivedData;
      }

      if (event.data.type === 'raceId') {
        const receivedData = event.data.payload;
        localStorage.setItem(SELECTED_RACE_ID, receivedData);
        this.raceId = receivedData;
      }

      if (event.data.type === 'voiceMode') {
        const receivedData = event.data.payload;
        localStorage.setItem('voiceMode', JSON.stringify(receivedData));
        this.voiceMode = receivedData;
      }

      if (event.data.type === 'mainColor') {
        const receivedData = event.data.payload;
        localStorage.setItem(MAIN_COLOR, receivedData);
        this.mainColor = receivedData;
      }

      if (event.data.type === 'keycloak-token') {
        localStorage.setItem('keycloak-token', event.data.token);
        this.access_token = event.data.token;
        window.location.reload();
      }

      if (event.data.type === 'races') {
        const receivedData = event.data.payload;
        localStorage.setItem(EVENT_RACES, JSON.stringify(receivedData));
        this.races = receivedData;
        this.getCurrentRace();
      }

      if (event.data.type === 'raceTypes') {
        const receivedData = event.data.payload;
        localStorage.setItem(RACE_TYPES, JSON.stringify(receivedData));
        this.raceTypes = receivedData;
      }

      if (
        event.data.type === 'runkeeper_token' ||
        event.data.type === 'strava_token' ||
        event.data.type === 'garmin_token'
      ) {
        if (this.hasRunkeeperTokenProcessed) return;
        if (event.data?.token) {
          const accountType = event.data.type.replace('_token', '');
          this.chatService.sendLinkAccountMessage(
            event.data.token,
            accountType,
            event.data?.token_secret,
          );
          this.hasRunkeeperTokenProcessed = true;
        }
      }
    });

    window.parent.postMessage(
      { type: 'iframeReady', payload: this.races },
      '*',
    );

    setTimeout(() => {
      this.checkUserAuth();
    }, 150);

    const root = document.documentElement;
    root.style.setProperty('--main-color', this.mainColor || '#6271FF');

    try {
      await this.getAudioDevices();
      const deviceId = localStorage.getItem('microphone-device-id');
      if (this.audioDevices.find((d) => d.deviceId === deviceId)) {
        this.selectedDeviceId = deviceId;
      } else {
        this.selectedDeviceId = this.audioDevices[0].deviceId;
      }
    } catch (error) {
      console.log('MICROPHONE ERROR', error);
    }
  }

  async getCurrentRace() {
    if (this.races) {
      const currentRace = this.races.find((i) => i.guid === this.raceId);
      if (currentRace) {
        this.currentRace = currentRace;
        this.race_units = currentRace.units;
      }
    }
  }

  selectRace(id: string) {
    if (id) {
      this.raceId = id;
      window.removeEventListener('storage', this.boundOnRunkeeperToken);
      this.chatService.disconnect();
      this.messages = [];
      this.prompt = '';
      this.getCurrentRace();
      this.loadRaceData();
    }
  }

  async ngAfterViewInit() {
    this.speechQueueProcessingInterval = setAsyncInterval(async () => {
      if (this.speechQueue.length && !this.isSpeechProcessing) {
        this.isSpeechProcessing = true;

        this.speechQueue.shift();

        const currentTime = new Date().getTime();
        if (
          this.speechStartTime &&
          (currentTime - this.speechStartTime) / 1000 > this.sttTimeout
        ) {
          this.speechStartTime = 0;
          if (this.submitSpeechTimeout) {
            clearTimeout(this.submitSpeechTimeout);
          }
          this.stopMicRecording();
        }

        this.isSpeechProcessing = false;
      }
    }, 1);
  }

  async checkUserAuth() {
    // const isTokenExpired = this.keycloakService.isTokenExpired();
    // if (isTokenExpired) {
    //   await this.keycloakService.login()
    // }

    const isTokenExpired = this.checkIfTokenExpired();

    if (!this.access_token || isTokenExpired) {
      this.keycloakLogin();
    } else {
      this.loadRaceData();
      this.getCurrentRace();
    }
  }

  checkIfTokenExpired() {
    if (!this.access_token) return true;
    try {
      const decoded: { exp: number } = jwtDecode(this.access_token);
      console.log('decoded', decoded);
      const currentTime = Math.floor(Date.now() / 1000);
      const isTokenExpired = decoded.exp < currentTime;

      if (isTokenExpired) {
        localStorage.removeItem('keycloak-token');
        this.access_token = '';
      }

      return isTokenExpired;
    } catch (error) {
      console.error('Invalid token', error);
      return true;
    }
  }

  async getAudioDevices(): Promise<void> {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      this.audioDevices = devices.filter(
        (device) => device.kind === 'audioinput',
      );
    } catch (error) {
      console.error('Error fetching audio devices:', error);
    }
  }

  selectDevice(deviceId: string | null): void {
    if (!deviceId) return;
    this.selectedDeviceId = deviceId;
    localStorage.setItem('microphone-device-id', deviceId);
  }

  async setupMicStream() {
    if (!this.audioContext) {
      this.audioContext = new AudioContext();
      await this.audioContext.audioWorklet.addModule(
        '/assets/js/audio-processor.js',
      );
    }

    this.deviceStream = await navigator.mediaDevices.getUserMedia({
      video: false,
      audio: this.selectedDeviceId
        ? {
            deviceId: this.selectedDeviceId,
          }
        : true,
    });

    this.audioWorkletNode = new AudioWorkletNode(
      this.audioContext,
      'audio-processor',
    );
    this.audioWorkletNode.port.onmessage = (event) => {
      const raw = event.data; // Отримання Float32Array
      if (raw === null) return;
      const downSampledBuffer = downSampleBuffer(raw, undefined, 16000);
      const pcmEncodedBuffer = pcmEncode(downSampledBuffer);
      this.chatService.sendGoogleSttData(pcmEncodedBuffer);
    };
    this.micStream = this.audioContext.createMediaStreamSource(
      this.deviceStream,
    );
    this.micStream.connect(this.audioWorkletNode);
    this.audioWorkletNode.connect(this.audioContext.destination);
  }

  private showMicDeniedDialog() {
    this.dialog.open(DialogComponent, {
      width: '300px',
      data: {
        headerTitle: 'Permissions request',
        contentText:
          'Please grant access to the microphone first, to use voice input',
        submitBtnText: 'Request again',
        onCancel: () => {},
      },
    });
  }

  async startMicRecording() {
    let isAccessibleMic = false;
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach((track) => track.stop());
      isAccessibleMic = true;
    } catch (error) {
      isAccessibleMic = false;
    }
    if (!isAccessibleMic) {
      return this.showMicDeniedDialog();
    }

    this.voiceMode = true;
    this.isRecording = true;
    this.speechStartTime = 0;
    this.chatService.startGoogleStt();
    await this.setupMicStream();
  }

  stopMicRecording() {
    this.chatService.stopGoogleStt();
    this.deviceStream?.getAudioTracks().forEach((track) => {
      track.stop();
    });
    if (this.audioWorkletNode) {
      this.audioWorkletNode.disconnect();
    }
    if (this.micStream) {
      this.micStream.disconnect();
    }
    if (this.audioContext) {
      this.audioContext.close().then(() => {
        this.audioContext = null;
      });
    }
    this.isRecording = false;
    this.voiceMode = false;
  }

  cancelVoiceMode() {
    if (this.isRecording) {
      this.stopMicRecording();
    }
    this.voiceMode = false;
  }

  onRunkeeperToken(event: StorageEvent) {
    console.log(this.hasRunkeeperTokenProcessed);
    if (this.hasRunkeeperTokenProcessed) return;
    if (event.storageArea !== localStorage) return;
    console.log(event);
    if (
      (event.key === 'runkeeper_token' ||
        event.key === 'strava_token' ||
        event.key === 'garmin_token') &&
      event.newValue
    ) {
      const runkeeperData = JSON.parse(event.newValue);
      if (runkeeperData.token) {
        const accountType = event.key.replace('_token', '');
        this.chatService.sendLinkAccountMessage(
          runkeeperData.token,
          accountType,
          runkeeperData?.token_secret,
        );
        this.hasRunkeeperTokenProcessed = true;
      }
      window.removeEventListener('storage', this.onRunkeeperToken);
    }
  }

  keycloakLogin() {
    const loginUrl = this.keycloakService.getKeycloakInstance().createLoginUrl({
      redirectUri: `${window.location.origin}/keycloak-auth-callback`,
    });
    const popup = window.open(
      loginUrl,
      '_blank',
      'width=600,height=800,scrollbars=yes',
    );

    if (!popup) {
      alert(
        'Popup blocked! Please allow popups for this site or click the Login button.',
      );
    } else {
      const interval = setInterval(() => {
        if (popup.closed) {
          clearInterval(interval);
        }
      }, 500);
    }
  }

  keycloakRegister() {
    const loginUrl = this.keycloakService
      .getKeycloakInstance()
      .createRegisterUrl({
        redirectUri: `${window.location.origin}/keycloak-auth-callback`,
      });
    const popup = window.open(
      loginUrl,
      '_blank',
      'width=600,height=800,scrollbars=yes',
    );

    if (!popup) {
      alert(
        'Popup blocked! Please allow popups for this site or click the register button.',
      );
    } else {
      const interval = setInterval(() => {
        if (popup.closed) {
          clearInterval(interval);
        }
      }, 500);
    }
  }

  ngOnDestroy() {
    window.removeEventListener('storage', this.boundOnRunkeeperToken);
    clearAsyncInterval(this.speechQueueProcessingInterval);
    this.chatService.disconnect();
  }

  private loadRaceData() {
    if (this.raceId) {
      this.raceService.getRaceById(this.raceId).subscribe({
        next: (response) => {
          this.race = response
            ? { ...response, starting_at: response?.starting_at.slice(0, -1) }
            : null;
          if (this.race?.guid) {
            this.getGpxData(this.race?.guid);
          }
          this.initializeChat();
        },
      });
    }
  }

  getGpxData(guid: string) {
    this.raceService.getGpxData(guid).subscribe((response) => {
      if (response?.start_point && response?.end_point) {
        if (response?.start_point.lat && response?.start_point.lon) {
          this.start_point = {
            lat: response.start_point.lat,
            lng: response.start_point.lon,
          };
        }

        this.elevationsData = response.tracks[0].geometry.coordinates;
      }
    });
  }

  private async initializeChat() {
    this.chatService.init(this.raceId!, this.access_token).then(() => {
      this.isChatLoading = true;
      this.chatService.onConnectionReady().subscribe(() => {
        this.isChatLoading = false;
        this.loadChatMessages();
        this.loadChatHistory();
        this.subscribeToSttMessage();
        this.subscribeToRawSttMessage();
        // this.subscribeToErrors()
      });
    });
  }

  private loadChatHistory() {
    if (this.prompt !== null) {
      this.chatService.getChatHistory().subscribe((data) => {
        this.processChatHistory(data);
      });
    }
  }

  private loadChatMessages() {
    this.chatService.getMessages().subscribe((message: ChatMessage) => {
      this.processIncomingMessage(message);
    });
  }

  private subscribeToSttMessage() {
    this.chatService.getSttMessage().subscribe((message: SttMessage) => {
      this.userMessage = message.message;
      this.sendMessage();
      this.stopMicRecording();
    });
  }

  private subscribeToRawSttMessage() {
    this.chatService.getRawSttMessage().subscribe((message: RawSttMessage) => {
      const alternatives = message.alternatives[0];
      this.userMessage = alternatives?.transcript;
    });
  }

  private processChatHistory(data: ChatMessage[]) {
    if (data?.length) {
      this.isChatHistoryEmpty = false;
      const formattedMessages = this.formatChatHistory(data);
      this.messages.push(...formattedMessages);
    }
    this.sendInitialMessage();
    this.scrollToBottom();
  }

  private sendInitialMessage() {
    if (!this.prompt) {
      this.scrollToBottom();
      return;
    }
    if (this.prompt && !AI_REQUESTS.includes(this.prompt)) {
      this.chatService.sendChangeScenarioMessage(AI_REQUESTS[0]);
      this.messages.push({
        text: this.prompt,
        is_system: false,
        sent_at: new Date(),
      });
      this.scrollToBottom();
      this.addLoadingMessage();
      setTimeout(() => {
        this.chatService.sendMessage(this.prompt);
        this.userMessage = '';
      }, 100);
    } else {
      if (this.prompt) {
        this.chatService.sendMessage(this.prompt);

        this.messages.push({
          text: this.prompt,
          is_system: false,
          sent_at: new Date(),
        });
        this.addLoadingMessage();
      }
      this.scrollToBottom();
    }
  }

  private addLoadingMessage() {
    this.isMessageLoading = true;
    this.messages.push({
      text: '',
      is_system: true,
      loading: true,
      sent_at: new Date(),
    });

    this.loadingMessageTimeout = setTimeout(() => {
      this.removeLoadingMessage();
      this.AIRequestError = true;
    }, AI_REQUEST_TIMEOUT);
  }

  private removeLoadingMessage() {
    const lastMessage = this.messages.find(
      (msg) => msg.loading && msg.is_system,
    );
    if (lastMessage) {
      this.messages = this.messages.filter((msg) => msg !== lastMessage);
    }

    if (this.loadingMessageTimeout) {
      clearTimeout(this.loadingMessageTimeout);
      this.loadingMessageTimeout = null;
    }
  }

  private processIncomingMessage(message: ChatMessage) {
    this.isChatLoading = false;
    if (this.loadingMessageTimeout) {
      clearTimeout(this.loadingMessageTimeout);
      this.loadingMessageTimeout = null;
    }

    const lastMessage = this.messages.find(
      (msg) => msg.loading && msg.is_system,
    );

    if (lastMessage) {
      this.updateLastMessage(lastMessage, message);
    } else {
      this.addNewMessage(message);
    }

    setTimeout(() => {
      this.scrollToBottom();
    }, 100);
  }

  scrollToBottom(): void {
    setTimeout(() => {
      this.chatContainer.nativeElement.scrollTo({
        top: this.chatContainer.nativeElement.scrollHeight,
        behavior: 'smooth',
      });
    }, 100);
  }

  private formatChatHistory(data: ChatMessage[]) {
    return data.flatMap((i) => {
      const baseMessage = {
        text: this.formatMessage(i.data?.message, i?.data?.links || []) || '',
        is_system: i.is_system,
        loading: false,
        sent_at: new Date(i.sent_at),
      };

      const gear = i?.data?.gears?.map((gear) => {
        const gearLinkText = {
          text: `Explore ${gear.brand}`,
          link: gear?.link,
        };
        const gearLabel = gear.name;

        return {
          text: gearLabel,
          link: gearLinkText,
          image: gear?.images ? gear?.images[0]?.file_url : '',
        };
      });

      const gearMessage = [
        {
          text: '',
          label: '',
          sent_at: new Date(i.sent_at),
          gearOptions: gear,
          is_system: true,
        },
      ];
      return gearMessage ? [baseMessage, ...gearMessage] : baseMessage;
    });
  }

  private addNewMessage(message: ChatMessage) {
    this.messages.push({
      text: this.formatMessage(
        message.data?.message,
        message?.data?.links || [],
      ),
      is_system: true,
      sent_at: new Date(),
    });

    if (message?.data?.options) {
      this.messages.push({
        text: '',
        is_options_message: true,
        is_system: false,
        sent_at: new Date(),
        options: message.data?.options || [],
      });
    }
    if (message?.data?.widgets) {
      let options: string[] = [];

      const mapWidgets = [
        INTERACTIVE_WIDGETS.MAP_START_POINT,
        INTERACTIVE_WIDGETS.MAP_FINISH_POINT,
        INTERACTIVE_WIDGETS.MAP_ROUTE,
        INTERACTIVE_WIDGETS.AID_STATIONS,
        INTERACTIVE_WIDGETS.MAP_POINTS_OF_INTEREST,
        INTERACTIVE_WIDGETS.MILE_POINT,
      ];
      if (mapWidgets.includes(message.data.widgets[0] as INTERACTIVE_WIDGETS)) {
        if (message.data?.gpx) {
          this.messages.push({
            text: '',
            is_options_message: false,
            is_system: true,
            sent_at: new Date(),
            is_map: true,
            gpx: message?.data?.gpx,
            widget: message.data.widgets[0],
            center_coordinate: message.data?.center_coordinate,
            options: message.data?.options || [],
          });
        }
      }

      const elevationWidgets = [INTERACTIVE_WIDGETS.ELEVATION_PROFILE];
      if (
        elevationWidgets.includes(
          message.data.widgets[0] as INTERACTIVE_WIDGETS,
        )
      ) {
        if (message.data?.gpx) {
          this.messages.push({
            text: '',
            is_options_message: false,
            is_system: true,
            sent_at: new Date(),
            is_elevation: true,
            gpx: message?.data?.gpx,
            widget: message.data.widgets[0],
            center_coordinate: message.data?.center_coordinate,
            options: message.data?.options || [],
          });
        }
      }

      if (message.data.widgets[0] === 'MAP_SEGMENT') {
        if (message.data?.gpx) {
          this.path = message?.data?.gpx.tracks.map((i) =>
            this.getMappedPath(i),
          );
          this.messages.push({
            text: '',
            is_options_message: false,
            is_system: true,
            sent_at: new Date(),
            is_map: true,
            options: message.data?.options || [],
          });
        }
      }

      if (message.data.widgets[0] === 'MAP_SEGMENT') {
        if (message.data?.gpx) {
          this.path = message?.data?.gpx.tracks.map((i) =>
            this.getMappedPath(i),
          );
          this.messages.push({
            text: '',
            is_options_message: false,
            is_system: true,
            sent_at: new Date(),
            is_map: true,
            options: message.data?.options || [],
          });
        }
      }

      if (message?.data?.widgets[0] === 'SEGMENT_PICKER') {
        options = [CHOOSE_SEGMENT_OPTION];
      }

      if (
        message?.data?.widgets[0] === 'RADIUS_PICKER' &&
        !this.radius_center_point
      ) {
        if (this.radius_center_point) {
          options = [SET_RADIUS_OPTION];
        } else {
          options = [SHOW_MAP_OPTION];
        }
      }

      if (
        options?.length &&
        message?.data?.widgets[0] === 'RADIUS_PICKER' &&
        options[0] !== SET_RADIUS_OPTION
      ) {
        this.sendWidgetOption(options);
      }

      this.messages.push({
        text: '',
        is_system: true,
        sent_at: new Date(),
        widget: message?.data?.widgets[0],
      });

      if (
        (options?.length && message?.data?.widgets[0] === 'SEGMENT_PICKER') ||
        options[0] === SET_RADIUS_OPTION
      ) {
        this.sendWidgetOption(options);
      }
    }

    if (message?.data?.table?.length) {
      const table = this.convertTableToElementData(message?.data?.table);
      this.messages.push({
        text: '',
        is_options_message: true,
        is_system: true,
        sent_at: new Date(),
        table: table,
      });
    }

    // if (message?.data?.widgets) {
    //   return this.messages.push({
    //     text: '',
    //     is_system: true,
    //     sent_at: new Date(),
    //     widget: message?.data?.widgets[0]
    //   })
    // }

    this.addGearMessages(message);
    this.addLinkAccountsMessage(message);
  }

  private addGearMessages(message: ChatMessage) {
    if (message.data?.gears?.length) {
      const gearMessage = message.data.gears.map((gear) => {
        const gearLinkText = {
          text: `Explore ${gear.brand}`,
          link: gear?.link,
        };
        const gearLabel = gear.name;

        return {
          text: gearLabel,
          link: gearLinkText,
          image: gear?.images ? gear?.images[0]?.file_url : '',
        };
      });

      this.messages.push({
        text: '',
        label: '',
        sent_at: new Date(),
        gearOptions: gearMessage,
        is_system: true,
      });
    }
  }

  private addLinkAccountsMessage(message: ChatMessage) {
    if (message.data?.link_accounts?.length) {
      const linkAccountsOptions = message.data.link_accounts.map((i) => ({
        name: i.name,
        logo: LINK_ACCOUNTS_IMAGES.find((j) => j.name === i.name)?.image || '',
      }));
      this.messages.push({
        text: this.formatMessage(
          message.data.message,
          message?.data?.links || [],
        ),
        sent_at: new Date(),
        link_accounts_options: linkAccountsOptions,
        is_system: true,
      });
    }
  }

  formatMessage(message: string, links: ChatMessageLink[]): SafeHtml {
    if (!message?.length) return '';

    let formattedMessage = message
      .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
      .replace(/\n/g, '<br>')
      .replace(/### (.*?)(<br>|$)/g, '<h3>$1</h3>');

    formattedMessage = formattedMessage.replace(
      /\[P(\d+)\]/g,
      (match, pIndex) => {
        const link = links.find((l) => l.reference === parseInt(pIndex, 10));

        return link
          ? `
        <a href="${link.url}" target="_blank" style="position: relative">
            <img
                src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KDTwhLS0gVXBsb2FkZWQgdG86IFNWRyBSZXBvLCB3d3cuc3ZncmVwby5jb20sIEdlbmVyYXRvcjogU1ZHIFJlcG8gTWl4ZXIgVG9vbHMgLS0+DQo8c3ZnIHdpZHRoPSI4MDBweCIgaGVpZ2h0PSI4MDBweCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPg0KPGcgaWQ9IkludGVyZmFjZSAvIEV4dGVybmFsX0xpbmsiPg0KPHBhdGggaWQ9IlZlY3RvciIgZD0iTTEwLjAwMDIgNUg4LjIwMDJDNy4wODAwOSA1IDYuNTE5NjIgNSA2LjA5MTggNS4yMTc5OUM1LjcxNTQ3IDUuNDA5NzMgNS40MDk3MyA1LjcxNTQ3IDUuMjE3OTkgNi4wOTE4QzUgNi41MTk2MiA1IDcuMDgwMDkgNSA4LjIwMDJWMTUuODAwMkM1IDE2LjkyMDMgNSAxNy40ODAxIDUuMjE3OTkgMTcuOTA3OUM1LjQwOTczIDE4LjI4NDIgNS43MTU0NyAxOC41OTA1IDYuMDkxOCAxOC43ODIyQzYuNTE5MiAxOSA3LjA3ODk5IDE5IDguMTk2OTEgMTlIMTUuODAzMUMxNi45MjEgMTkgMTcuNDggMTkgMTcuOTA3NCAxOC43ODIyQzE4LjI4MzcgMTguNTkwNSAxOC41OTA1IDE4LjI4MzkgMTguNzgyMiAxNy45MDc2QzE5IDE3LjQ4MDIgMTkgMTYuOTIxIDE5IDE1LjgwMzFWMTRNMjAgOVY0TTIwIDRIMTVNMjAgNEwxMyAxMSIgc3Ryb2tlPSIjMDAwMDAwIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPg0KPC9nPg0KPC9zdmc+"
                alt="External link"
                style="width: 16px; height: 16px; position: absolute; top: 0;margin-left: 6px; filter: invert(43%) sepia(13%) saturate(4555%) hue-rotate(206deg) brightness(103%) contrast(104%)"
            />
        </a>`
          : match;
      },
    );

    return this.sanitizer.bypassSecurityTrustHtml(formattedMessage);
  }

  convertTableToElementData(table: string[][]) {
    this.displayedColumns = table[0].map((header: string) => header);

    this.displayedColumns = table[0].map((header: string) => header);

    const rows = table.slice(1).map((row: string[]) => {
      const rowObj: { [key: string]: string } = {};
      row.forEach((cell: string, index: number) => {
        rowObj[this.displayedColumns[index]] = cell;
      });
      return rowObj;
    });

    if (this.newDataSource) {
      this.newDataSource.data = rows;
    }

    return rows;
  }

  sendWidgetOption(options: string[]) {
    this.messages.push({
      text: '',
      is_options_message: true,
      is_system: false,
      sent_at: new Date(),
      is_widget_options: true,
      options,
    });
  }

  private updateLastMessage(
    lastMessage: MappedChatMessage,
    message: ChatMessage,
  ) {
    this.isMessageLoading = false;
    lastMessage.loading = false;
    lastMessage.text =
      this.formatMessage(message.data?.message, message?.data?.links || []) ||
      '';
    if (message.data?.options) {
      this.messages.push({
        text: '',
        is_options_message: true,
        is_system: false,
        sent_at: new Date(),
        options: message.data?.options || [],
      });
    }

    if (message.data?.gpx) {
      this.path = message?.data?.gpx.tracks.map((i) => this.getMappedPath(i));

      if (this.path) {
        this.path.map((i: MappedPathOutput[], index) => {
          const photo = this.googleMapsService.getStreetViewPhotoUrl(
            i[0].lat,
            i[0].lng,
          );
          this.segment_tracks?.push({
            photo_url: photo || '',
            segment: `Segment ${index + 1}`,
            elevationGain: i[0]?.elevationGain || 0,
            totalSimilarity: i[0]?.totalSimilarity || 0,
            distance: i[0].distance || 0,
          });
        });
      }

      this.messages.push({
        text: '',
        is_options_message: false,
        is_system: true,
        sent_at: new Date(),
        is_map: true,
        options: message.data?.options || [],
      });
    }

    if (message?.data?.widgets) {
      let options: string[] = [];

      if (message?.data?.widgets[0] === 'SEGMENT_PICKER') {
        options = [CHOOSE_SEGMENT_OPTION];
      }

      if (message?.data?.widgets[0] === 'RADIUS_PICKER') {
        if (this.radius_center_point) {
          options = [SET_RADIUS_OPTION];
        } else {
          options = [SHOW_MAP_OPTION];
        }
      }

      if (
        options?.length &&
        message?.data?.widgets[0] === 'RADIUS_PICKER' &&
        !this.radius_center_point
      ) {
        this.sendWidgetOption(options);
      }
      this.messages.push({
        text: '',
        is_system: true,
        sent_at: new Date(),
        widget: message?.data?.widgets[0],
      });

      if (
        (options?.length && message?.data?.widgets[0] === 'SEGMENT_PICKER') ||
        (message?.data?.widgets[0] === 'RADIUS_PICKER' &&
          this.radius_center_point)
      ) {
        this.sendWidgetOption(options);
      }
    }

    if (message?.data?.table?.length) {
      const table = this.convertTableToElementData(message?.data?.table);
      this.messages.push({
        text: '',
        is_options_message: true,
        is_system: true,
        sent_at: new Date(),
        table: table,
      });
    }

    this.addGearMessages(message);
    this.addLinkAccountsMessage(message);
  }

  onFitnessClick(provider: string) {
    this.hasRunkeeperTokenProcessed = false;
    this.oidcService.openAuthWindow(provider);
  }

  getMappedPath(track: Track | GpxWaypoints): MappedPathOutput[] {
    if (track && track?.geometry && track?.properties) {
      const { distance, elevationGain, totalSimilarity } = track.properties;

      if (
        track?.properties?.distance &&
        track?.properties?.elevationGain &&
        track?.properties?.totalSimilarity
      ) {
        return track.geometry.coordinates.map((i) => ({
          lat: i.lat,
          lng: i.lon,
          distance: Number(distance?.toFixed(1)) || 0,
          elevationGain: Number(elevationGain?.toFixed(0)) || 0,
          totalSimilarity: Number(totalSimilarity?.toFixed(0)) || 0,
        }));
      } else {
        return track?.geometry?.coordinates.map((i) => ({
          lat: i.lat,
          lng: i.lon,
        }));
      }
    } else {
      return [];
    }
  }

  isSameDay(date1: Date, date2: Date): boolean {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  onOptionClick(option: string) {
    if (
      option === CHOOSE_SEGMENT_OPTION ||
      option === SHOW_MAP_OPTION ||
      option === SET_RADIUS_OPTION
    ) {
      if (option === CHOOSE_SEGMENT_OPTION && this.segment_coordinates) {
        this.chatService.sendSegmentCoordinatesMessage({
          start_index: this.segment_coordinates?.startPointIndex,
          end_index: this.segment_coordinates?.endPointIndex,
          selected_distance: this.segment_distance,
        });
      }

      if (option === SHOW_MAP_OPTION) {
        const lastMessageIndex = this.messages.length - 2;
        const lastMessage = this.messages[lastMessageIndex];

        if (lastMessage.options) {
          delete lastMessage.options;
          lastMessage.text = SHOW_MAP_OPTION;
          lastMessage.is_options_message = false;
          lastMessage.is_widget_options = false;
        }

        this.messages[lastMessageIndex] = lastMessage;

        this.scrollToBottom();

        if (this.radius_center_point) {
          this.messages.push({
            text: '',
            is_options_message: true,
            is_system: false,
            sent_at: new Date(),
            is_widget_options: true,
            options: [SET_RADIUS_OPTION],
          });
          return;
        }
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.radius_center_point = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            this.messages.push({
              text: '',
              is_options_message: true,
              is_system: false,
              sent_at: new Date(),
              is_widget_options: true,
              options: [SET_RADIUS_OPTION],
            });
          },
          (error) => {
            this.radius_center_point = {
              lat: this.start_point?.lat || 40.73061,
              lng: this.start_point?.lng || -73.935242,
            };
            this.messages.push({
              text: '',
              is_options_message: true,
              is_system: false,
              sent_at: new Date(),
              is_widget_options: true,
              options: [SET_RADIUS_OPTION],
            });
          },
          {
            enableHighAccuracy: true,
            maximumAge: 0,
          },
        );
        return;
      }

      if (
        option === SET_RADIUS_OPTION &&
        this.race_radius &&
        this.radius_center_point
      ) {
        const lastMessageIndex = this.messages.length - 1;

        const lastMessage = this.messages[lastMessageIndex];
        if (lastMessage.options) {
          delete lastMessage.options;
          lastMessage.text = SET_RADIUS_OPTION;
          lastMessage.is_options_message = false;
          lastMessage.is_widget_options = false;
        }

        this.messages[lastMessageIndex] = lastMessage;

        this.scrollToBottom();
        const data: RaceRadiusData = {
          radius: this.race_radius,
          point: {
            lat: this.radius_center_point.lat,
            lon: this.radius_center_point.lng,
          },
        };
        this.chatService.sendMapRadiusMessage(data);
        this.addLoadingMessage();
        return;
      }
    }
    this.chatService.sendMessage(option);

    const lastMessageIndex = this.messages.length - 1;
    const lastMessage = this.messages[lastMessageIndex];

    if (lastMessage.options) {
      delete lastMessage.options;
    }

    this.messages[lastMessageIndex] = lastMessage;

    this.messages.push({ text: option, is_system: false, sent_at: new Date() });
    this.scrollToBottom();
    this.addLoadingMessage();
  }

  closeDrawer() {
    const message = { type: 'closeDrawer', payload: null }; // You can include additional payload if needed
    window.parent.postMessage(message, '*');
    // this.isOpen = false;
    // this.handleChangeDrawerState.emit(false);
  }

  sendMessage() {
    if (this.userMessage.trim()) {
      this.chatService.sendChangeScenarioMessage(AI_REQUESTS[0]);
      this.messages.push({
        text: this.userMessage.trim(),
        is_system: false,
        sent_at: new Date(),
      });
      this.scrollToBottom();
      this.addLoadingMessage();
      setTimeout(() => {
        this.chatService.sendMessage(this.userMessage.trim());
        this.userMessage = '';
      }, 100);
    }
  }

  onSegmentSelected(
    event: {
      startPointIndex: number;
      endPointIndex: number;
      segmentDistance: number;
    } | null,
  ) {
    if (event && event?.startPointIndex < event?.endPointIndex) {
      this.segment_coordinates = {
        startPointIndex: event.startPointIndex,
        endPointIndex: event.endPointIndex,
      };
      this.segment_distance = event?.segmentDistance;
    } else {
      this.segment_coordinates = null;
    }
  }

  onRadiusSelect(event: { radius: number; center: Coordinates }) {
    this.race_radius = event?.radius;
    this.radius_center_point = event?.center;
  }

  onPromptClick(prompt: string) {
    this.chatService.sendMessage(prompt);
    this.messages.push({ text: prompt, is_system: false, sent_at: new Date() });
    this.scrollToBottom();
    this.addLoadingMessage();
  }

  onLoginClick() {
    this.keycloakLogin();
  }

  onRegisterClick() {
    this.keycloakRegister();
  }
}
