export const distanceConverter = (
  value: number = 0,
  unit: string = 'metric',
): number => {
  if (!value) return 0;

  if (unit === 'metric') {
    return Number((value * 1.60934).toFixed(2));
  } else if (unit === 'imperial') {
    return Number((value / 1.60934).toFixed(2));
  } else {
    return value;
  }
};
